import React, { useEffect, useState } from "react";
import {DashboardGetGvexMonthlyGainedValue, DashboardGetProjectMonthlyGainedValue} from "../../../../Api/Services";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


function BarCompany({ ChartColor,companyId }) {

    const [isLoading, setLoading] = useState(true);
    const [Data, setData] = useState([])
    const [Name, setName] = useState([])

    const start = async () => {
        try {

            const DashboardGetProjectMonthlyGainedValueData = await DashboardGetProjectMonthlyGainedValue(companyId)
            if (DashboardGetProjectMonthlyGainedValueData.status && DashboardGetProjectMonthlyGainedValueData.status === 200) {
                let MonthlyGained = DashboardGetProjectMonthlyGainedValueData.data.data;
                let listMet = [];
                MonthlyGained.forEach((e,key) => {
                    if (!listMet.includes(e.projectName)) {
                        listMet.push(e.projectName);
                    }
                });
                setName(listMet)
                let listDate = [];
                MonthlyGained.forEach((e,key) => {
                    if (!listDate.includes(e.dataYear + "/" + e.dataMonth)) {
                        listDate.push(e.dataYear + "/" + e.dataMonth);
                    }
                });

                let data = [];
                listDate.forEach((itemDate,key) => {
                    let ee = { Date: itemDate }
                    listMet.forEach((itemNname,key) => {
                        let t = MonthlyGained.find(e => e.dataYear + "/" + e.dataMonth === itemDate && e.projectName === itemNname)
                        ee[itemNname] = t === undefined ? 0 : t.sumSavingValue
                    })
                    data.push(ee)
                });
                data.splice(0, data.length - 5)
                setData(data)
            }

        } catch
        (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        start();
    }, []
    )

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading boxShadow"}>
                <div className="d-flex justify-content-center align-items-center flex-column w-100 element">
                    <div className="d-flex justify-content-center align-items-center w-100 m-5">
                        <span className="titleChart">Monthly Earned Value</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 ">

                        <BarChart
                            width={1000}
                            height={300}
                            data={Data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="0" stroke="#E5E5EF" vertical={false} />
                            <XAxis dataKey="Date" fontSize={"10px"} />
                            <YAxis fontSize={"11px"} />
                            <Tooltip />
                            <Legend />
                            {Name.map((item, key) =>
                                <Bar dataKey={item} fill={ChartColor[key]} radius={"10"} strokeWidth="3" />
                            )}
                        </BarChart>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default BarCompany;
