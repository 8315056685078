import "./AccountsAE.css";
import InputCus from "../InputCus/Text/InputCus";
import { useEffect, useState } from "react";
import Select from "../InputCus/Select/Select";
import { AddCompanies, GetCompanySearch, GetIndustry, UpdateCompanies } from "../../Api/Services";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import bg from "../../assets/Image/image 13.png";
import face from "../../assets/Image/face.jpg";
import { checkEmail, checkWebSite } from "../../Common/check";

function AccountsAE() {
    const [dataDropDown, setDataDropDown] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [form, setForm] = useState({})

    const navigate = useNavigate();

    let { id } = useParams();
    const start = async () => {
        try {
            const GetIndustryData = await GetIndustry()
            if (GetIndustryData.status && GetIndustryData.status === 200) {
                let a = [];
                GetIndustryData.data.data.forEach((e) => {
                    a.push({ value: e.id, title: e.latinName })
                })
                setDataDropDown(a)
                if (id) {
                    const GetCompanySearchData = await GetCompanySearch({ id: id })
                    if (GetCompanySearchData.status && GetCompanySearchData.status === 200) {
                        setForm(GetCompanySearchData.data.data.dataList[0]);
                    }
                }
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        start();
    }, []
    )

    const AddUpdate = async () => {
        let validation = true
        if (form.name === "") {
            toast.error('Fill in the name !')
            validation = false
        }
        if (form.industryId === 0) {
            toast.error('Fill in the industryId !')
            validation = false

        }
        if (form.contactPerson === "") {
            toast.error('Fill in the contactPerson !')
            validation = false

        }
        if (form.cellNumber === "") {
            toast.error('Fill in the cellNumber !')
            validation = false

        }
        if (form.phone === "") {
            toast.error('Fill in the phone !')
            validation = false
        }
        if (form.email === "") {
            toast.error('Fill in the email !')
            validation = false
        }
        if (!checkEmail(form.email)) {
            validation = false
        }
        if (form.website === "") {
            toast.error('Fill in the website !')
            validation = false
        }
        if (!checkWebSite(form.website)) {
            validation = false
        }
        if (form.linkedIn === "") {
            toast.error('Fill in the linkedIn !')
            validation = false
        }
        if (form.logo === "") {
            toast.error('Fill in the logo !')
            validation = false
        }
        if (validation) {
            setLoading(true)
            try {
                if (id) {
                    const UpdateCompaniesData = await UpdateCompanies(form, id)
                    if (UpdateCompaniesData.status && UpdateCompaniesData.status === 200) {
                        toast.success('Your information has been update!');
                        navigate("/Panel/Accounts");
                    }
                } else {
                    const AddCompaniesData = await AddCompanies(form)
                    if (AddCompaniesData.status && AddCompaniesData.status === 200) {
                        toast.success('Your information has been add!');
                        navigate("/Panel/Accounts");
                    }
                }
            } catch (error) {
                console.error("Error during login:", error);
            } finally {
                setLoading(false);
                
            }
        }
    }

    const handleChangeSearch = (e) => {
        const newForm = { ...form };
        newForm[e.target.id] = e.target.value;
        setForm(newForm);
    }
    const onChangeLogo = (e) => {
        const image = e.target.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(image);

        reader.onload = () => setForm(lastForm => ({
            ...lastForm,
            logo: reader.result.toString().substring(reader.result.toString().indexOf(",") + 1)
        }));

    }
    const onChangeCover = (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();

        reader.readAsDataURL(image);

        reader.onload = () => setForm(lastForm => ({
            ...lastForm,
            coverPic: reader.result.toString().substring(reader.result.toString().indexOf(",") + 1)
        }));

    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-between align-items-start p-5">
                    <div className="d-flex justify-content-start align-items-center flex-column element me-5 w-75">
                        <div className="d-flex justify-content-start align-items-center flex-wrap p-5 ">
                            <InputCus
                                type="text"
                                name="name"
                                id="name"
                                text="name"
                                value={form.name || ""}
                                onChange={handleChangeSearch}
                            />
                            <Select
                                type="text"
                                name="industryId"
                                id="industryId"
                                text="industryId"
                                value={form.industryId || ""}
                                onChange={handleChangeSearch}
                                option={dataDropDown}
                            />
                            <InputCus
                                type="text"
                                name="contactPerson"
                                id="contactPerson"
                                text="Contact Person"
                                value={form.contactPerson || ""}
                                onChange={handleChangeSearch}
                            /> <InputCus
                                type="text"
                                name="contactPerson1"
                                id="contactPerson1"
                                text="Contact Person 1"
                                value={form.contactPerson1 || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="contactPerson2"
                                id="contactPerson2"
                                text="Contact Person 2"
                                value={form.contactPerson2 || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="contactPerson3"
                                id="contactPerson3"
                                text="Contact Person 3"
                                value={form.contactPerson3 || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="contactPerson4"
                                id="contactPerson4"
                                text="Contact Person 4"
                                value={form.contactPerson4 || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="cellNumber"
                                id="cellNumber"
                                text="Cell Number"
                                value={form.cellNumber || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="phone"
                                id="phone"
                                text="Phone"
                                value={form.phone || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="email"
                                id="email"
                                text="Email"
                                value={form.email || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="website"
                                id="website"
                                text="Website"
                                value={form.website || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="linkedIn"
                                id="linkedIn"
                                text="LinkedIn"
                                value={form.linkedIn || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="description"
                                id="description"
                                text="Description"
                                value={form.description || ""}
                                onChange={handleChangeSearch}
                                width="w-75"
                            />

                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 ps-5 flex-wrap">
                            <div className="d-flex justify-content-start align-items-start flex-column me-3">
                                <div className="d-flex justify-content-start align-items-center w-100">
                                    <span className="spanBtnAccount mb-2">Logo Update</span>
                                </div>
                                <div className="d-flex justify-content-start align-items-center">
                                    <input onChange={(e) => {
                                        onChangeLogo(e)
                                    }} className="BtnAccount me-2" type="file" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-start align-items-start flex-column">
                                <div className="d-flex justify-content-start align-items-center w-100">
                                    <span className="spanBtnAccount mb-2">Cover Upload</span>
                                </div>
                                <div className="d-flex justify-content-start align-items-center">
                                    <input onChange={(e) => {
                                        onChangeCover(e)
                                    }} className="BtnAccount me-2" type="file" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 p-5 m-5">
                            <button onClick={() => {
                                AddUpdate()
                            }} className="butAUAccount">{id ? "Update Account" : "Add Account"} </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center element flex-column w-25">
                        <div className="d-flex justify-content-center align-items-center mt-4 ">
                            <img className="w-75" src={form.coverPic ? "data:image/jpeg;base64," + form.coverPic : bg}
                                alt="" />
                            <img className="face" src={form.logo ? "data:image/jpeg;base64," + form.logo : face}
                                alt="" />
                        </div>
                        <span className="m-2">{form.name || "Name"}</span>
                        <span className="m-2">{form.email || "Email"}</span>
                        <span className="m-2 mb-4">{form.description || "Description"}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountsAE;
