import "./Login.css";
import imgMorex from '../../assets/Image/Morex.png'
import Vector from "../../assets/Icon/Vector.png"
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as api from "../../Api/Services";
import ConvertIdentity from "../../Common/ConvertIdentity";

function Login() {

    const [inputValue, setInputValue] = useState({
        username: "",
        password: "",
    });
    const [flagLoading, setFlagLoading] = useState(false);

    const navigate = useNavigate();

    const LoginCheck = async () => {
        try {
            setFlagLoading(true)
            const getTokenRes = await api.getToken(inputValue);
            if (getTokenRes.status && getTokenRes.status === 200 && getTokenRes.data.access_token) {
                const getCompanyByUserNameRes = await api.getCompanyByUserName(inputValue.username);
                if (getCompanyByUserNameRes.status && getCompanyByUserNameRes.status === 200) {
                    const getUserUsernameRes = await api.getUserUsername(inputValue.username);
                    if (getUserUsernameRes.status && getUserUsernameRes.status === 200) {
                        const fetchIdentityRes = await api.fetchIdentity(getUserUsernameRes.data.data[0].id);
                        if (fetchIdentityRes.status && fetchIdentityRes.status === 200) {
                            let Identity = ConvertIdentity(fetchIdentityRes.data.data.userClaimValues);
                            localStorage.setItem("Identity", JSON.stringify(Identity));
                            localStorage.setItem("Username", inputValue.username);
                            localStorage.setItem("CompanyUserName", getCompanyByUserNameRes.data.data);
                            localStorage.setItem("Token", getTokenRes.data.access_token);
                            navigate("/Panel");
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setFlagLoading(false);
        }
    }
    return (
        <>
            {flagLoading &&
                <div className="scopeLoading d-flex justify-content-center align-items-center"
                     style={{height: window.innerHeight}}>
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={flagLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center Bg-Login"
                     style={{height: window.innerHeight}}>
                    <div className="SideLogin d-flex justify-content-center align-items-center flex-column">
                        <img className="imgMorex m-5" src={imgMorex} alt="Morex"/>
                        <span className="fontSigning">Sign in</span>
                        <div className="d-flex flex-column m-3">
                            <span className="lblLogin p-1">Username</span>
                            <input value={inputValue.username}
                                   onChange={(e) => {
                                       setInputValue({
                                           ...inputValue,
                                           username: e.target.value,
                                       });
                                   }}
                                   type="text" placeholder="test1@gmail.com" className="inputLogin"/>
                        </div>
                        <div className="d-flex flex-column m-3">
                            <div className="d-flex justify-content-between align-items-center p-1">
                                <span className="lblLogin">Password</span>
                                <span className="ForgetLogin">Forgot your password?</span>
                            </div>
                            <input value={inputValue.password} onChange={(e) => {
                                setInputValue({
                                    ...inputValue,
                                    password: e.target.value,
                                });
                            }}
                                   type="password" placeholder="********" className="inputLogin inputLoginPassword"/>
                        </div>
                        <div className="BtnLogin d-flex justify-content-around align-items-center m-5" onClick={() => {
                            LoginCheck()
                        }}>
                            <button className="textLogin">SIGN IN</button>
                            <img className="VectorLogin" src={Vector} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;
