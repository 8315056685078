import "./Panel.css";
import imgMorex from "../../assets/Image/Morex.png";
import imgDashboardPanel from "../../assets/Icon/Dashboard.png";
import imgUserDashboard from "../../assets/Icon/monitor-heartbeat-user.png";
import imgAccounts from "../../assets/Icon/User,Profile.png";
import imgUsers from "../../assets/Icon/User, Circle.png";
import imgProjects from "../../assets/Icon/Task list, Document, Text.png";
import imgMetrics from "../../assets/Icon/Business, Chart, Metrics, Laptop.png";
import imgDataEntry from "../../assets/Icon/list-task-checkmark.png";
import imgApproval from "../../assets/Icon/setting-edit.png";
import imgActivities from "../../assets/Icon/stamp-checkmark-circle.png";
import Arrow from "../../assets/Icon/Arrow, Up.png";
import imgLogout from "../../assets/Icon/login-logout-arrow.png";
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

function Panel({ element }) {
    const side = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [FormIdentity] = useState(JSON.parse(localStorage.getItem('Identity')));



    const Logout = () => {
        localStorage.removeItem("Identity");
        localStorage.removeItem("Username");
        localStorage.removeItem("CompanyUserName");
        localStorage.removeItem("Token");
        navigate("/login");
    }
    const Back = () => {
        navigate(-1);
    }
    return (
        <>
            <div className="d-flex Panel">
                <div className="side d-flex justify-content-start align-items-center flex-column" ref={side}>
                    <img className="imgMorex m-5" src={imgMorex} alt="Morex" />
                    {FormIdentity && FormIdentity.Dashboard.DashboardGetMetricMonetary &&
                        <div onClick={() => {
                            navigate("/panel/Dashboard")
                        }}
                            className={location.pathname.includes("Dashboard") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>
                            <img src={imgDashboardPanel} alt="" />
                            <button>DASHBOARD</button>
                        </div>
                    }
                    {/* {FormIdentity && FormIdentity.UserDashboard.UserDashboardGetMetricMonetary &&

                        <div onClick={() => {
                            navigate("/panel/UserDashboard")
                        }}
                            className={location.pathname.includes("UserDashboard") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>
                            <img src={imgUserDashboard} alt="" />
                            <button>USERDASHBOARD</button>
                        </div>
                    } */}
                    {FormIdentity && FormIdentity.Account.Read &&
                        <div onClick={() => {
                            navigate("/panel/Accounts")
                        }}
                            className={location.pathname.includes("Accounts") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>
                            <img src={imgAccounts} alt="" />
                            <button>ACCOUNTS</button>
                        </div>
                    }
                    {FormIdentity && FormIdentity.User.Read &&
                        <div onClick={() => {
                            navigate("/panel/Users")
                        }}
                            className={location.pathname.includes("Users") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>

                            <img src={imgUsers} alt="" />
                            <button>USERS</button>
                        </div>
                    }
                    {FormIdentity && FormIdentity.Project.Read &&
                        <div onClick={() => {
                            navigate("/panel/Projects")
                        }}
                            className={location.pathname.includes("Projects") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>

                            <img src={imgProjects} alt="" />
                            <button>PROJECTS</button>
                        </div>
                    }
                    {FormIdentity && FormIdentity.Metric.Read &&
                        <div onClick={() => {
                            navigate("/panel/Metrics")
                        }}
                            className={location.pathname.includes("Metrics") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>
                            <img src={imgMetrics} alt="" />
                            <button>METRICS</button>
                        </div>
                    }
                    {FormIdentity && FormIdentity.DataEntry.Read &&
                        <div onClick={() => {
                            navigate("/panel/DataEntry")
                        }}
                            className={location.pathname.includes("DataEntry") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>
                            <img src={imgDataEntry} alt="" />
                            <button>DATA ENTRY</button>
                        </div>
                    }
                    <div onClick={() => {
                        navigate("/panel/Approval")
                    }}
                        className={location.pathname.includes("Approval") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>
                        <img src={imgApproval} alt="" />
                        <button>APPROVAL</button>
                    </div>
                    {FormIdentity && FormIdentity.Activities.Read &&
                        <div onClick={() => {
                            navigate("/panel/Activities")
                        }}
                            className={location.pathname.includes("Activities") ? "itemSide d-flex justify-content-start align-items-center itemActive" : "itemSide d-flex justify-content-start align-items-center"}>
                            <img src={imgActivities} alt="" />
                            <button>ACTIVITIES</button>
                        </div>
                    }
                </div>

                <div className="d-flex align-items-start PanelElement w-100 p-5 flex-column">
                    <div className="d-flex justify-content-between w-100 mb-3">
                        <div className="d-flex justify-content-center align-items-center">
                            <img onClick={() => Back()} className="bgArrow p-1" src={Arrow} alt="" />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" onClick={() => {
                            Logout()
                        }}>
                            <button className="btnLogout">LOGOUT</button>
                            <img src={imgLogout} alt="" />
                        </div>
                    </div>
                    <div className="d-flex flex-column w-100 ">
                        {element}

                    </div>

                </div>
            </div>

        </>
    )
}

export default Panel;
