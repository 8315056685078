import MetricList from "./Component/MetricList";
import DetProject from "./Component/DetProject";
import AreaProject from "./Component/AreaProject";
import BarProject from "./Component/BarProject";
import PieProject from "./Component/PieProject";
import './DashboardProject.css'
import {
    DashboardGetAccountMonetary,
    GetCompanySearch,
    GetHardSoftSavingAccount,
    GetProjectSearch
} from "../../../Api/Services";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";


function DashboardProject() {
    const [projectsName, setprojectsName] = useState();
    const [isLoading, setLoading] = useState(true);
    console.log("sssssssssss")
    let {id} = useParams();


    const ChartColor = [
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE"
    ]

    const start = async () => {
        try {
            const GetProjectSearchData = await GetProjectSearch({id: id})
            if (GetProjectSearchData.status && GetProjectSearchData.status === 200) {
                setprojectsName(GetProjectSearchData.data.data.dataList[0].projectName)
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )
    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column">
                    <div className="w-100" style={{"padding-bottom": "30px", "border-bottom": "2px solid #e3e3e3"}}>
                        <DetProject projectId={id} projectsName={projectsName}/>
                    </div>
                    <div className="w-100 mt-4">
                        <MetricList projectId={id}/>
                    </div>
                    <div className="w-100 mt-4">
                        <AreaProject projectsName={projectsName} projectId={id}/>
                    </div>
                    <div className="w-100 mt-4">
                        <BarProject projectId={id} ChartColor={ChartColor}/>
                    </div>
                    <div className="w-100 mt-4">
                        <PieProject projectId={id} ChartColor={ChartColor}/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DashboardProject;
