import "./UsersAE.css";
import InputCus from "../InputCus/Text/InputCus";
import { useEffect, useState } from "react";
import Select from "../InputCus/Select/Select";
import { AddUsers, ApplyIdentity, CompanyList, fetchIdentity, GetJobTitle, GetUserSearch, UpdateUsers } from "../../Api/Services";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdaptorDTO from "../../Common/AdaptorDTO";

const ModelIdentity = {
    userId: 1,
    userName: "superAdmin",
    userClaimValues: [
        {
            claimValue: "GetUser",
            claimValuePersian: "نمایش لیست کاربران",
            isSelected: true
        },
        {
            claimValue: "FilterUser",
            claimValuePersian: "فیلتر لیست کاربران",
            isSelected: true
        },
        {
            claimValue: "AddUser",
            claimValuePersian: " افزدون کاربر جدید",
            isSelected: true
        },
        {
            claimValue: "EditUser",
            claimValuePersian: "ویرایش کاربر",
            isSelected: true
        },
        {
            claimValue: "DeleteUser",
            claimValuePersian: "حذف کاربر",
            isSelected: true
        },
        {
            claimValue: "ViewAccount",
            claimValuePersian: "نمایش لیست اکانت ها",
            isSelected: true
        },
        {
            claimValue: "FilterAccount",
            claimValuePersian: "نمایش لیست اکانت ها",
            isSelected: true
        },
        {
            claimValue: "AddAccount",
            claimValuePersian: " افزدون اکانت جدید",
            isSelected: true
        },
        {
            claimValue: "EditAccount",
            claimValuePersian: "ویرایش اکانت",
            isSelected: true
        },
        {
            claimValue: "DeleteAccount",
            claimValuePersian: "حذف اکانت",
            isSelected: true
        },
        {
            claimValue: "ViewMetric",
            claimValuePersian: "ViewMetric",
            isSelected: true
        },
        {
            claimValue: "FilterMetric",
            claimValuePersian: "فیلتر لیست متریک ها",
            isSelected: true
        },
        {
            claimValue: "AddMetric",
            claimValuePersian: " افزدون متریک جدید",
            isSelected: true
        },
        {
            claimValue: "EditMetric",
            claimValuePersian: "ویرایش متریک",
            isSelected: true
        },
        {
            claimValue: "DeleteMetric",
            claimValuePersian: "حذف متریک",
            isSelected: true
        },
        {
            claimValue: "GetProject",
            claimValuePersian: "نمایش لیست پروژه ها",
            isSelected: true
        },
        {
            claimValue: "FilterProject",
            claimValuePersian: "فیلتر لیست پروژ ها",
            isSelected: true
        },
        {
            claimValue: "AddProject",
            claimValuePersian: " افزدون پروژه جدید",
            isSelected: true
        },
        {
            claimValue: "EditProject",
            claimValuePersian: "ویرایش پروژه",
            isSelected: true
        },
        {
            claimValue: "DeleteProject",
            claimValuePersian: "حذف پروژه",
            isSelected: true
        },
        {
            claimValue: "GetDataEntry",
            claimValuePersian: "نمایش لیست دیتا ها",
            isSelected: true
        },
        {
            claimValue: "FilterDataEntry",
            claimValuePersian: "فیلتر لیست دیتا ها",
            isSelected: true
        },
        {
            claimValue: "AddDataEntry",
            claimValuePersian: " افزدون دیتا جدید",
            isSelected: true
        },
        {
            claimValue: "EditDataEntry",
            claimValuePersian: "ویرایش دیتا",
            isSelected: true
        },
        {
            claimValue: "DeleteDataEntry",
            claimValuePersian: "حذف دیتا",
            isSelected: true
        },
        {
            claimValue: "DashboardGetMetricMonetary",
            claimValuePersian: "DashboardGetMetricMonetary",
            isSelected: true
        },
        {
            claimValue: "DashboardGetMetricTotalGainedValue",
            claimValuePersian: "DashboardGetMetricTotalGainedValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetProjectMonetary",
            claimValuePersian: "DashboardGetProjectMonetary",
            isSelected: true
        },
        {
            claimValue: "DashboardGetProjectMetrics",
            claimValuePersian: "DashboardGetProjectMetrics",
            isSelected: true
        },
        {
            claimValue: "DashboardGetProjectTotalGainedValue",
            claimValuePersian: "DashboardGetProjectTotalGainedValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetMetricPercentageGainedValue",
            claimValuePersian: "DashboardGetMetricPercentageGainedValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetMetricMonthlyGainedValue",
            claimValuePersian: "DashboardGetMetricMonthlyGainedValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetSumProjectsValue",
            claimValuePersian: "DashboardGetSumProjectsValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetAccountMonetary",
            claimValuePersian: "DashboardGetAccountMonetary",
            isSelected: true
        },
        {
            claimValue: "DashboardGetAccountProjects",
            claimValuePersian: "DashboardGetAccountProjects",
            isSelected: true
        },
        {
            claimValue: "DashboardGetAccountTotalGainedValue",
            claimValuePersian: "DashboardGetAccountTotalGainedValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetProjectMonthlyGainedValue",
            claimValuePersian: "DashboardGetProjectMonthlyGainedValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetProjectPercentageGainedValue",
            claimValuePersian: "DashboardGetProjectPercentageGainedValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetGvexMonetary",
            claimValuePersian: "DashboardGetGvexMonetary",
            isSelected: true
        },
        {
            claimValue: "DashboardGetGvexAccounts",
            claimValuePersian: "DashboardGetGvexAccounts",
            isSelected: true
        },
        {
            claimValue: "DashboardGetGVEXROIPortfolio",
            claimValuePersian: "DashboardGetGVEXROIPortfolio",
            isSelected: true
        },
        {
            claimValue: "DashboardGetGvexMonthlyGainedValue",
            claimValuePersian: "DashboardGetGvexMonthlyGainedValue",
            isSelected: true
        },
        {
            claimValue: "DashboardGetGvexAccountPercentageGainedValue",
            claimValuePersian: "DashboardGetGvexAccountPercentageGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetMetricMonetary",
            claimValuePersian: "UserDashboardGetMetricMonetary",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetMetricTotalGainedValue",
            claimValuePersian: "UserDashboardGetMetricTotalGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetProjectMonetary",
            claimValuePersian: "UserDashboardGetProjectMonetary",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetProjectMetrics",
            claimValuePersian: "UserDashboardGetProjectMetrics",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetProjectTotalGainedValue",
            claimValuePersian: "UserDashboardGetProjectTotalGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetMetricPercentageGainedValue",
            claimValuePersian: "UserDashboardGetMetricPercentageGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetMetricMonthlyGainedValue",
            claimValuePersian: "UserDashboardGetMetricMonthlyGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetSumProjectsValue",
            claimValuePersian: "UserDashboardGetSumProjectsValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetAccountMonetary",
            claimValuePersian: "UserDashboardGetAccountMonetary",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetAccountProjects",
            claimValuePersian: "UserDashboardGetAccountProjects",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetAccountTotalGainedValue",
            claimValuePersian: "UserDashboardGetAccountTotalGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetProjectMonthlyGainedValue",
            claimValuePersian: "UserDashboardGetProjectMonthlyGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetProjectPercentageGainedValue",
            claimValuePersian: "UserDashboardGetProjectPercentageGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetGvexMonetary",
            claimValuePersian: "UserDashboardGetGvexMonetary",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetGvexAccounts",
            claimValuePersian: "UserDashboardGetGvexAccounts",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetGVEXROIPortfolio",
            claimValuePersian: "UserDashboardGetGVEXROIPortfolio",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetGvexMonthlyGainedValue",
            claimValuePersian: "UserDashboardGetGvexMonthlyGainedValue",
            isSelected: true
        },
        {
            claimValue: "UserDashboardGetGvexAccountPercentageGainedValue",
            claimValuePersian: "UserDashboardGetGvexAccountPercentageGainedValue",
            isSelected: true
        },
        {
            claimValue: "ViewActivities",
            claimValuePersian: "ViewActivities",
            isSelected: true
        }
    ]
}
const ModelUser = {
    firstName: "",
    lastName: "",
    jobTitleId: 0,
    cellNumber: "",
    linkdin: "",
    userProfilePic: "",
    companyId: 0,
    userName: "",
    email: "",
    password: ""
}
function UsersAE() {
    const [dataDropDownAccount, setDropDownAccount] = useState([]);
    const [dataDropDownJob, setDataDropDownJob] = useState([]);
    const [FormIdentity, setFormIdentity] = useState({ ...ModelIdentity });
    const [isLoading, setLoading] = useState(true);
    const [form, setForm] = useState({})

    const navigate = useNavigate();

    let { id } = useParams();
    const start = async () => {
        try {
            const GetJobTitleData = await GetJobTitle()
            if (GetJobTitleData.status && GetJobTitleData.status === 200) {
                let a = [];
                GetJobTitleData.data.data.forEach((e) => {
                    a.push({ value: e.id, title: e.latinName })
                })
                setDataDropDownJob(a)
                const CompanyListData = await CompanyList({})
                if (CompanyListData.status && CompanyListData.status === 200) {
                    let b = [];
                    CompanyListData.data.data.forEach((e) => {
                        b.push({ value: e.id, title: e.description })
                    })
                    setDropDownAccount(b)
                    if (id) {
                        const GetUserSearchData = await GetUserSearch({ id: id })
                        if (GetUserSearchData.status && GetUserSearchData.status === 200) {
                            setForm(AdaptorDTO({ ...ModelUser }, GetUserSearchData.data.data.dataList[0]));
                            const FetchIdentityData = await fetchIdentity(id)
                            if (FetchIdentityData.status && FetchIdentityData.status === 200) {
                                setFormIdentity(FetchIdentityData.data.data);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        start();
    }, []
    )

    const AddUpdate = async () => {
        let validation = true
        if (form.firstName === "") {
            toast.error('Fill in the firstName !')
            validation = false
        }
        if (form.lastName === "") {
            toast.error('Fill in the lastName !')
            validation = false

        }
        if (form.jobTitleId === 0) {
            toast.error('Fill in the jobTitleId !')
            validation = false

        }
        if (form.cellNumber === "") {
            toast.error('Fill in the cellNumber !')
            validation = false

        }
        if (form.linkdin === "") {
            toast.error('Fill in the linkdin !')
            validation = false

        }
        if (form.userProfilePic === "") {
            toast.error('Fill in the userProfilePic !')
            validation = false

        }
        if (form.companyId === 0) {
            toast.error('Fill in the companyId !')
            validation = false

        }
        if (form.userName === "") {
            toast.error('Fill in the userName !')
            validation = false

        }
        if (form.email === "") {
            toast.error('Fill in the email !')
            validation = false

        }
        if (form.password === "") {
            toast.error('Fill in the password !')
            validation = false
        }
        if (validation) {
            setLoading(true)
            try {
                if (id) {
                    const UpdateUsersData = await UpdateUsers(form, id)
                    if (UpdateUsersData.status && UpdateUsersData.status === 200) {
                        let FormIdentityCopy = { ...FormIdentity }
                        FormIdentityCopy.userId = UpdateUsersData.data.data.id
                        FormIdentityCopy.userName = UpdateUsersData.data.data.userName
                        const ApplyIdentityData = await ApplyIdentity(FormIdentityCopy)
                        if (ApplyIdentityData.status && ApplyIdentityData.status === 200) {
                            toast.success('Your information has been add!');
                            navigate("/Panel/Users");
                        }
                    }
                } else {
                    const AddUsersData = await AddUsers(form)
                    if (AddUsersData.status && AddUsersData.status === 200) {
                        let FormIdentityCopy = { ...FormIdentity }
                        FormIdentityCopy.userId = AddUsersData.data.data.id
                        FormIdentityCopy.userName = AddUsersData.data.data.userName
                        const ApplyIdentityData = await ApplyIdentity(FormIdentityCopy)
                        if (ApplyIdentityData.status && ApplyIdentityData.status === 200) {
                            toast.success('Your information has been update!');
                            navigate("/Panel/Users");
                        }
                    }
                }
            } catch (error) {
                console.error("Error during login:", error);
            } finally {
                setLoading(false);

            }
        }
    }

    const handleChangeSearch = (e) => {
        const newForm = { ...form };
        newForm[e.target.id] = e.target.value;
        setForm(newForm);
    }

    function handleChangeInputCheckBox(e) {
        let newFormIdentity = { ...ModelIdentity };
        let checked = e.target.checked;
        let id = e.target.id;
        if (id.search("Get") === 0) {
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === (id)).isSelected = checked
            let Filter = e.target.id.replace("Get", "Filter");
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === (Filter)).isSelected = checked
        } else if (id === "ViewActivities") {
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === (id)).isSelected = checked
        } else if (id.search("View") === 0) {
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === (id)).isSelected = checked
            let Filter = e.target.id.replace("View", "Filter");
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === (Filter)).isSelected = checked
        } else
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === (id)).isSelected = checked
        setFormIdentity(newFormIdentity);
    }

    const handleChangeInputDashboard = (e) => {
        let newFormIdentity = { ...ModelIdentity };
        let checked = e.target.checked;
        let id = e.target.id;
        if (id.search("User") === 0) {
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetMetricMonetary")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetMetricTotalGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetProjectMonetary")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetProjectMetrics")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetProjectTotalGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetMetricPercentageGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetMetricMonthlyGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetSumProjectsValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetAccountMonetary")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetAccountProjects")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetAccountTotalGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetProjectMonthlyGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetProjectPercentageGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetGvexMonetary")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetGvexAccounts")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetGVEXROIPortfolio")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetGvexMonthlyGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("UserDashboardGetGvexAccountPercentageGainedValue")).isSelected = checked;
        }
        if (id.search("Dashboard") === 0) {
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetMetricMonetary")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetMetricTotalGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetProjectMonetary")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetProjectMetrics")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetProjectTotalGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetMetricPercentageGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetMetricMonthlyGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetSumProjectsValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetAccountMonetary")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetAccountProjects")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetAccountTotalGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetProjectMonthlyGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetProjectPercentageGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetGvexMonetary")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetGvexAccounts")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetGVEXROIPortfolio")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetGvexMonthlyGainedValue")).isSelected = checked;
            (newFormIdentity.userClaimValues).find(Identity => Identity.claimValue === ("DashboardGetGvexAccountPercentageGainedValue")).isSelected = checked;
        }
        setFormIdentity(newFormIdentity);
    }

    const onChangeProfile = (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();

        reader.readAsDataURL(image);

        reader.onload = () => setForm(lastForm => ({
            ...lastForm,
            userProfilePic: reader.result.toString().substring(reader.result.toString().indexOf(",") + 1)
        }));


    }



    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-between align-items-start p-5">
                    <div className="d-flex justify-content-start align-items-center flex-column element w-100 p-5">
                        <div className="d-flex justify-content-start align-items-center flex-wrap  ">
                            <Select
                                type="text"
                                name="companyId"
                                id="companyId"
                                text="Account"
                                value={form.companyId || ""}
                                onChange={handleChangeSearch}
                                option={dataDropDownAccount}
                            />
                            <InputCus
                                type="text"
                                name="firstName"
                                id="firstName"
                                text="First Name"
                                value={form.firstName || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="lastName"
                                id="lastName"
                                text="Last Name"
                                value={form.lastName || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="userName"
                                id="userName"
                                text="UserName"
                                value={form.userName || ""}
                                onChange={handleChangeSearch}
                            />
                            <Select
                                type="text"
                                name="jobTitleId"
                                id="jobTitleId"
                                text="Job Title"
                                value={form.jobTitleId || ""}
                                onChange={handleChangeSearch}
                                option={dataDropDownJob}
                            />
                            <InputCus
                                type="password"
                                name="password"
                                id="password"
                                text="Password"
                                value={form.password || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="email"
                                id="email"
                                text="Email"
                                value={form.email || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="cellNumber"
                                id="cellNumber"
                                text="Cell"
                                value={form.cellNumber || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="linkdin"
                                id="linkdin"
                                text="Linkdin"
                                value={form.linkdin || ""}
                                onChange={handleChangeSearch}
                            />


                        </div>
                        <div className="d-flex justify-content-center align-items-center w-100 tableC">
                            <table className="table">
                                <thead>
                                    <tr className="trTable">
                                        <th></th>
                                        <th>Accounts</th>
                                        <th>Users</th>
                                        <th>Projects</th>
                                        <th>Metrics</th>
                                        <th>Data Entry</th>
                                        <th>Activities</th>
                                        <th>DashBoard</th>
                                        <th>User DashBoard</th>
                                    </tr>
                                </thead>
                                <tbody className="identitystyle">
                                    <tr>
                                        <td>View</td>
                                        <td>
                                            <input id="ViewAccount"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "ViewAccount")?.isSelected}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                id="GetUser"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "GetUser")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="GetProject"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "GetProject")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="ViewMetric"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "ViewMetric")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="GetDataEntry"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "GetDataEntry")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="ViewActivities"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "ViewActivities")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="DashboardGetMetricMonetary"
                                                type="checkbox"
                                                onChange={handleChangeInputDashboard}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "DashboardGetMetricMonetary")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="UserDashboardGetMetricMonetary"
                                                type="checkbox"
                                                onChange={handleChangeInputDashboard}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "UserDashboardGetMetricMonetary")?.isSelected}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Edit</td>
                                        <td>
                                            <input
                                                id="EditAccount"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "EditAccount")?.isSelected}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                id="EditUser"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "EditUser")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="EditProject"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "EditProject")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="EditMetric"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "EditMetric")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="EditDataEntry"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "EditDataEntry")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                        </td>

                                        <td>
                                        </td>

                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Add</td>
                                        <td>
                                            <input
                                                id="AddAccount"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "AddAccount")?.isSelected}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                id="AddUser"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "AddUser")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="AddProject"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "AddProject")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="AddMetric"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "AddMetric")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="AddDataEntry"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "AddDataEntry")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                        </td>

                                        <td>
                                        </td>

                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Delete</td>
                                        <td>
                                            <input
                                                id="DeleteAccount"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "DeleteAccount")?.isSelected}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                id="DeleteUser"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "DeleteUser")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="DeleteProject"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "DeleteProject")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="DeleteMetric"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "DeleteMetric")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                id="DeleteDataEntry"
                                                type="checkbox"
                                                onChange={handleChangeInputCheckBox}
                                                checked={(FormIdentity.userClaimValues).find(Identity => Identity.claimValue === "DeleteDataEntry")?.isSelected}
                                            />
                                        </td>

                                        <td>
                                        </td>

                                        <td>
                                        </td>

                                        <td>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 mt-5">
                            <input onChange={(e) => {
                                onChangeProfile(e)
                            }} className="BtnAccount me-2" type="file" />
                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 m-5">
                            <button onClick={() => {
                                AddUpdate()
                            }} className="butAUAccount" >{id ? "Update Account" : "Add Account"} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UsersAE;
