import './DetSuperAdmin.css'
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "../../../../Common/Tools";
import { DashboardGetGvexMonetary } from "../../../../Api/Services";
import gicon from"../../../../assets/Icon/Statistics, Analytics,Arrow, Up, Circle,.png"

function DetSuperAdmin() {
    const [sumGvexMetricsValue, setsumGvexMetricsValue] = useState();
    const [isLoading, setLoading] = useState(true);


    const start = async () => {
        try {
            const DashboardGetGvexMonetaryData = await DashboardGetGvexMonetary()
            if (DashboardGetGvexMonetaryData.status && DashboardGetGvexMonetaryData.status === 200) {
                setsumGvexMetricsValue(DashboardGetGvexMonetaryData.data.data.sumAccountMetricsValue);
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        start();
    }, []
    )

    return (

        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="d-flex justify-content-start align-items-center w-100">
                    <div className="d-flex justify-content-between align-items-center GvexMonetaryRadi p-2">
                        <div className='GvexMonetaryRadi-icon d-flex justify-content-center align-items-center'>
                            <img src={gicon}/>
                        </div>
                        <div className='d-flex justify-content-center align-items-end flex-column'>
                            <span className="name">All Accounts ROI</span>
                            <span className="value">${numberWithCommas(sumGvexMetricsValue)}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetSuperAdmin;
