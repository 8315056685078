import "./Metrics.css";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    CompanyList, DeleteMetric,
    DeleteProject,
    GetMetricSearch, GetMetricType,
    GetProjectSearch,
    MetricList,
    ProjectList, UserList
} from "../../Api/Services";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import imgPlus from "../../assets/Icon/pluse.png";
import Select from "../InputCus/Select/Select";
import InputCus from "../InputCus/Text/InputCus";
import imgDelete from "../../assets/Icon/Delete, Disabled.png";
import imgDoc from "../../assets/Icon/Document, Content.png";


function Metrics() {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [dataDropDownAccount, setDataDropDownAccount] = useState([])
    const [dataDropDownProject, setDataDropDownProject] = useState([])
    const [dataDropDownMetric, setDataDropDownMetric] = useState([])
    const [Search, setSearch] = useState({})
    const [dataDropDownUser, stataDropDownUser] = useState([])
    const [ListMetricType, setListMetricType] = useState([])
    const [FormIdentity, setFormIdentity] = useState();
    const navigate = useNavigate();

    const start = async () => {
        try {
            setFormIdentity(JSON.parse(localStorage.getItem('Identity')));
            const GetMetricSearchData = await GetMetricSearch(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetMetricSearchData.status && GetMetricSearchData.status === 200) {
                setData(GetMetricSearchData.data.data.dataList)
                const MetricListtData = await MetricList({})
                if (MetricListtData.status && MetricListtData.status === 200) {
                    let a = [];
                    MetricListtData.data.data.forEach((e) => {
                        a.push({value: e.id, title: e.description})
                    })
                    setDataDropDownMetric(a)
                    const CompanyListData = await CompanyList({})
                    if (CompanyListData.status && CompanyListData.status === 200) {
                        let c = [];
                        CompanyListData.data.data.forEach((e) => {
                            c.push({value: e.id, title: e.description})
                        })
                        setDataDropDownAccount(c)
                        const ProjectListData = await ProjectList({})
                        if (ProjectListData.status && ProjectListData.status === 200) {
                            let b = [];
                            ProjectListData.data.data.forEach((e) => {
                                b.push({value: e.id, title: e.description})
                            })
                            setDataDropDownProject(b)
                            const UserListData = await UserList({})
                            if (UserListData.status && UserListData.status === 200) {
                                let d = [];
                                UserListData.data.data.forEach((e) => {
                                    d.push({value: e.id, title: e.description})
                                })
                                stataDropDownUser(d)
                                const GetMetricTypeData = await GetMetricType()
                                if (GetMetricTypeData.status && GetMetricTypeData.status === 200) {
                                    let f = [];
                                    GetMetricTypeData.data.data.forEach((e) => {
                                        f.push({value: e.id, title: e.latinName})
                                    })
                                    setListMetricType(f)
                                }
                            }
                        }
                    }
                }
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    const Clear = async () => {
        setSearch({})
        setLoading(true)
        try {
            const GetMetricSearchData = await GetMetricSearch(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetMetricSearchData.status && GetMetricSearchData.status === 200) {
                setData(GetMetricSearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const Searched = async () => {
        try {
            setLoading(true)
            let searching = {...Search}
            let Paging = {
                gridParameters: {
                    pageNumber: 1,
                    pageSize: 1000
                }
            }
            const GetMetricSearchData = await GetMetricSearch({...searching, ...Paging})
            if (GetMetricSearchData.status && GetMetricSearchData.status === 200) {
                setData(GetMetricSearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const confirmDelete = (e) => {
        confirmAlert({
            title: 'Delete Metric',
            message: 'Are You Sure To Do Remove Metric ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteItem(e)
                },
                {
                    label: 'No',
                }
            ]
        });
    };
    const DeleteItem = async (e) => {

        try {
            setLoading(true)
            const DeleteMetricData = await DeleteMetric(e)
            if (DeleteMetricData.status && DeleteMetricData.status === 200) {
                toast.success('Your information has been delete!');
                await start();
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }

    }
    const Add = () => {
        navigate("/Panel/Metrics/Add");
    }
    const Edite = (id) => {
        navigate("/Panel/Metrics/Edite/" + id);
    }
    const handleChangeSearch = (e) => {
        const newSearch = {...Search};
        newSearch[e.target.id] = e.target.value;
        setSearch(newSearch);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column element p-5">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <span className="AccountsTitle">Metrics</span>
                        {FormIdentity && FormIdentity.Metric.Create && <img onClick={() => {
                            Add()
                        }} src={imgPlus} alt=""/>}
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <Select
                            type="text"
                            name="companyId"
                            id="companyId"
                            text="Account"
                            value={Search.companyId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownAccount}
                        />
                        <Select
                            type="text"
                            name="projectId"
                            id="projectId"
                            text="Project"
                            value={Search.projectId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownProject}
                        />
                        <Select
                            type="text"
                            name="metricTypeId"
                            id="metricTypeId"
                            text="Metric Type"
                            value={Search.metricTypeId || ""}
                            onChange={handleChangeSearch}
                            option={ListMetricType}
                        />
                        <Select
                            type="text"
                            name="metricId"
                            id="metricId"
                            text="Metric"
                            value={Search.metricId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownMetric}
                        />
                        <InputCus
                            type="number"
                            name="baseLine"
                            id="baseLine"
                            text="BaseLine"
                            value={Search.baseLine || ""}
                            onChange={handleChangeSearch}
                        />
                        <InputCus
                            type="text"
                            name="unit"
                            id="unit"
                            text="Unit"
                            value={Search.unit || ""}
                            onChange={handleChangeSearch}
                        />

                        <InputCus
                            type="number"
                            name="standardValue"
                            id="standardValue"
                            text="Standard Value"
                            value={Search.standardValue || ""}
                            onChange={handleChangeSearch}
                        />
                        <Select
                            type="text"
                            name="ownerId"
                            id="ownerId"
                            text="Owner"
                            value={Search.ownerId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownUser}
                        />
                    </div>
                    <div className="d-flex justify-content-start align-items-center w-100">
                        <button onClick={() => {
                            Searched()
                        }} className="m-3 btnSearch">Search
                        </button>
                        <button onClick={() => {
                            Clear()
                        }} className="btnCancel">Clear
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 p-2 tableC">
                        <table className="table">
                            <thead>
                            <tr className="trTable">
                                <th>Account</th>
                                <th>Project</th>
                                <th>Metric</th>
                                <th>Metric Type</th>
                                <th>BaseLine</th>
                                <th>Unit</th>
                                <th>Standard value</th>
                                <th>Owner</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item, key) =>
                                <tr key={key}>
                                    <td>
                                        {item.companyName}
                                    </td>
                                    <td>
                                        {item.projectName}
                                    </td>
                                    <td>
                                        {item.title}
                                    </td>
                                    <td>
                                        {item.metricTypeDescription}
                                    </td>
                                    <td>
                                        {item.baseLine}
                                    </td>

                                    <td>
                                        {item.unit}
                                    </td>

                                    <td>
                                        {item.standardValue}
                                    </td>

                                    <td>
                                        {item.ownerDescription}
                                    </td>


                                    <td className="td-actions">

                                        {FormIdentity && FormIdentity.Metric.Delete &&
                                            <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                  title="Remove Metric ...">
                                                <img className="me-2" src={imgDelete} alt="Remove Metric ..."
                                                     onClick={() => {
                                                         confirmDelete(item.id)
                                                     }}/>
                                            </span>}
                                        {FormIdentity && FormIdentity.Metric.Update &&
                                            <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                  title="Edite Metric ...">

                                            <img className="me-2" src={imgDoc} alt=" Edite Metric ..." onClick={() => {
                                                Edite(item.id)
                                            }}/>
                                            </span>}
                                    </td>

                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Metrics;
