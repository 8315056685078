import './DetMetric.css'
import React, {useEffect, useState} from "react";
import gicon from "../../../../assets/Icon/Statistics, Analytics,Arrow, Up, Circle,.png"
import {
    DashboardGetAccountMonetary, DashboardGetMetricMonetary,
    GetHardSoftSavingAccount,
    GetHardSoftSavingProject
} from "../../../../Api/Services";
import {numberWithCommas} from "../../../../Common/Tools";

function DetMetric(props) {
    const [metricMonetary, setmetricMonetary] = useState();
    const [isLoading, setLoading] = useState(true);


    const start = async () => {
        try {
            const DashboardGetMetricMonetaryData = await DashboardGetMetricMonetary(props.metricId)
            if (DashboardGetMetricMonetaryData.status && DashboardGetMetricMonetaryData.status === 200) {
                setmetricMonetary(DashboardGetMetricMonetaryData.data.data.sumMetricValue)
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    return (

        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="d-flex justify-content-start align-items-center w-100">
                    <div className="d-flex flex-column GvexMonetaryRadi p-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className='GvexMonetaryRadi-icon d-flex justify-content-center align-items-center'>
                                <img src={gicon}/>
                            </div>
                            <div className='d-flex justify-content-center align-items-end flex-column'>
                                <span
                                    className="name">{props.metricName ? props.metricName : "Saving"}</span>
                                <span
                                    className="value">{metricMonetary ? "$" + numberWithCommas(metricMonetary) : "projectsMonetary"}</span>
                            </div>
                        </div>
                        {/*<div className="d-flex justify-content-between mt-2">*/}
                        {/*    <div className='d-flex justify-content-center align-items-center'>*/}
                        {/*        <span className="nameProjects me-2">Hard Saving</span>*/}
                        {/*        <span*/}
                        {/*            className="valueProjects">{HardSoft ? "$" + numberWithCommas(HardSoft.sumHardSavingMetrics) :0}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className='d-flex justify-content-center align-items-center'>*/}
                        {/*        <span className="nameProjects me-2">Soft Saving</span>*/}
                        {/*        <span*/}
                        {/*            className="valueProjectsSuss">{HardSoft ? "$" + numberWithCommas(HardSoft.sumSoftSavingMetrics) : 0}</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetMetric;
