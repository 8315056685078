import "./ForgetPassword.css";
import imgMorex from '../../assets/Image/Morex.png'
import Vector from "../../assets/Icon/Vector.png"
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as api from "../../Api/Services";


function ForgetPassword() {

    const [inputValue, setInputValue] = useState({
        email: ""
    });

    const navigate = useNavigate();


    const LoginCheck = async () => {
        console.log("sssss")
    }


    return (
        <div className="w-100 d-flex justify-content-start align-items-center Bg-Login">
            <div className="SideLogin d-flex justify-content-center align-items-center flex-column">
                <img className="imgMorex m-5" src={imgMorex} alt="Morex"/>
                <div className="d-flex">
                    <span className="fontForgetPassword p-1">Forget password</span>
                </div>
                <div className="d-flex flex-column m-3">
                    <span className="lblLogin p-1">Email</span>
                    <input value={inputValue.email}
                           onChange={(e) => {
                               setInputValue({
                                   ...inputValue,
                                   email: e.target.value,
                               });
                           }}
                           type="text" placeholder="test1@gmail.com" className="inputLogin"/>
                </div>
                <div className="BtnLogin d-flex justify-content-around align-items-center m-5" onClick={() => {
                    LoginCheck()
                }}>
                    <button className="textLogin">SUBMIT</button>
                    <img className="VectorLogin" src={Vector} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;
