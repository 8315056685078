import "./DataEntryAE.css";
import InputCus from "../InputCus/Text/InputCus";
import {useEffect, useState} from "react";
import {
    AddDataEnters,
    AddMetrics,
    CompanyList,
    GetCompanyUsers,
    GetDataEntrySearch,
    MetricList,
    ProjectList, UpdateDataEnters,
    UpdateMetrics,
} from "../../Api/Services";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Select from "../InputCus/Select/Select";

function DataEntryAE() {
    const [isLoading, setLoading] = useState(true);
    const [form, setForm] = useState({});
    const [ProjectId, setProjectId] = useState();
    const [CompanyId, setCompanyId] = useState();
    const [Metric, setMetricList] = useState([]);
    const [Project, setProjectList] = useState([]);
    const [Company, setCompanyList] = useState([]);

    const navigate = useNavigate();

    let {id} = useParams();
    const start = async () => {
        try {
            if (id) {
                const GetDataEntrySearchData = await GetDataEntrySearch({id: id})
                if (GetDataEntrySearchData.status && GetDataEntrySearchData.status === 200) {
                    setForm(GetDataEntrySearchData.data.data.dataList[0]);
                    setCompanyId(GetDataEntrySearchData.data.data.dataList[0].companyId)
                    setProjectId(GetDataEntrySearchData.data.data.dataList[0].projectId)
                    const ProjectListData = await ProjectList({companyId: GetDataEntrySearchData.data.data.dataList[0].companyId})
                    if (ProjectListData.status && ProjectListData.status === 200) {
                        let q = [];
                        ProjectListData.data.data.forEach((e) => {
                            q.push({value: e.id, title: e.description})
                        })
                        setProjectList(q)
                        const MetricListData = await MetricList({projectId: GetDataEntrySearchData.data.data.dataList[0].projectId})
                        if (MetricListData.status && MetricListData.status === 200) {
                            let w = [];
                            MetricListData.data.data.forEach((e) => {
                                w.push({value: e.id, title: e.description})
                            })
                            setMetricList(w)
                        }
                    }
                }
            }

            const CompanyListData = await CompanyList({})
            if (CompanyListData.status && CompanyListData.status === 200) {
                let a = [];
                CompanyListData.data.data.forEach((e) => {
                    a.push({value: e.id, title: e.description})
                })
                setCompanyList(a)
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    const AddUpdate = async () => {
        try {
            setLoading(true)
            if (id) {
                const UpdateDataEntersData = await UpdateDataEnters(form, id)
                if (UpdateDataEntersData.status && UpdateDataEntersData.status === 200) {
                    toast.success('Your information has been update!');
                    navigate("/Panel/DataEntry");
                }
            } else {
                const AddDataEntersData = await AddDataEnters(form)
                if (AddDataEntersData.status && AddDataEntersData.status === 200) {
                    toast.success('Your information has been add!');
                    navigate("/Panel/DataEntry");
                }
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);

        }
    }

    const handleCompany = async (e) => {
        setCompanyId(e.target.value);
        if (e.target.value !== undefined) {
            if (e.target.value === "-1") {
                setMetricList([])
                setProjectList([])
            } else {
                try {
                    setLoading(true)
                    const ProjectListData = await ProjectList({companyId: e.target.value})
                    if (ProjectListData.status && ProjectListData.status === 200) {
                        let g = [];
                        ProjectListData.data.data.forEach((e) => {
                            g.push({value: e.id, title: e.description})
                        })
                        setProjectList(g)
                    }
                } catch (error) {
                    console.error("Error during login:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
    }
    const handleProject = async (e) => {
        setProjectId(e.target.value);
        if (e.target.value !== undefined) {
            if (e.target.value === "-1") {
                setMetricList([])
            } else {
                try {
                    setLoading(true)
                    const MetricListData = await MetricList({projectId: e.target.value})
                    if (MetricListData.status && MetricListData.status === 200) {
                        let g = [];
                        MetricListData.data.data.forEach((e) => {
                            g.push({value: e.id, title: e.description})
                        })
                        setMetricList(g)
                    }
                } catch (error) {
                    console.error("Error during login:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
    }

    function handleChangeInputDate(e) {
        const newForm = {...form};
        let startDate = newForm.startDate;
        let newDateStart;
        let newDateEnd;
        if (startDate) {
            let dateStartDate = startDate.split("-");

            if (e.target.id === "Year") {
                newDateStart = e.target.value + "-" + dateStartDate[1] + "-01";
                newDateEnd = e.target.value + "-" + dateStartDate[1] + "-20";
                newForm.startDate = newDateStart;
                newForm.timeline = newDateEnd
            }
            if (e.target.id === "Month") {
                newDateStart = dateStartDate[0] + "-" + e.target.value + "-01";
                newDateEnd = dateStartDate[0] + "-" + e.target.value + "-20";
                newForm.startDate = newDateStart;
                newForm.timeline = newDateEnd
            }
        } else {
            if (e.target.id === "Year") {
                newDateStart = e.target.value + "- -01";
                newDateEnd = e.target.value + "- -20";
                newForm.startDate = newDateStart;
                newForm.timeline = newDateEnd
            }
            if (e.target.id === "Month") {
                newDateStart = " -" + e.target.value + "-01";
                newDateEnd = " -" + e.target.value + "-20";
                newForm.startDate = newDateStart;
                newForm.timeline = newDateEnd
            }
        }
        setForm(newForm);
    }

    const onChangeProfile = (e) => {
        let image = e.target.files[0];
        let reader = new FileReader();

        reader.readAsDataURL(image);

        reader.onload = () => setForm(lastForm => ({
            ...lastForm,
            file: reader.result.toString().substring(reader.result.toString().indexOf(",") + 1)
        }));


    }

    function handleChangeSearch(e) {
        const newForm = {...form};
        newForm[e.target.id] = e.target.value;
        setForm(newForm);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-between align-items-start p-5">
                    <div className="d-flex justify-content-start align-items-center flex-column element w-100 p-5">
                        <div className="d-flex justify-content-start align-items-center flex-wrap  ">
                            <Select
                                type="text"
                                name="companyId"
                                id="companyId"
                                text="Account"
                                value={CompanyId || ""}
                                onChange={handleCompany}
                                option={Company}
                            />
                            <Select
                                type="text"
                                name="projectId"
                                id="projectId"
                                text="Project"
                                value={ProjectId || ""}
                                onChange={handleProject}
                                option={Project}
                            />
                            <Select
                                type="text"
                                name="metricId"
                                id="metricId"
                                text="Metric"
                                value={form.metricId || ""}
                                onChange={handleChangeSearch}
                                option={Metric}
                            />
                            <Select
                                type="text"
                                name="Year"
                                id="Year"
                                text="Start Date Year"
                                value={form.startDate && form.startDate.split("-")[0] || ""}
                                onChange={handleChangeInputDate}
                                option={[
                                    {value: "2019", title: "2019"},
                                    {value: "2020", title: "2020"},
                                    {value: "2021", title: "2021"},
                                    {value: "2022", title: "2022"},
                                    {value: "2023", title: "2023"},
                                    {value: "2024", title: "2024"},
                                    {value: "2025", title: "2025"},
                                    {value: "2026", title: "2026"},
                                    {value: "2027", title: "2027"},
                                    {value: "2028", title: "2028"},
                                    {value: "2029", title: "2029"},
                                    {value: "2030", title: "2030"},
                                ]}
                            />
                            <Select
                                type="text"
                                name="Month"
                                id="Month"
                                text="Start Date Month"
                                value={form.startDate && form.startDate.split("-")[1]}
                                onChange={handleChangeInputDate}
                                option={[
                                    {value: "01", title: "January"},
                                    {value: "02", title: "February"},
                                    {value: "03", title: "March"},
                                    {value: "04", title: "April"},
                                    {value: "05", title: "May"},
                                    {value: "06", title: "June"},
                                    {value: "07", title: "July"},
                                    {value: "08", title: "August"},
                                    {value: "09", title: "September"},
                                    {value: "10", title: "October"},
                                    {value: "11", title: "November"},
                                    {value: "12", title: "December"}
                                ]}
                            />
                            <InputCus
                                type="number"
                                name="metricAbsoluteValue"
                                id="metricAbsoluteValue"
                                text="Metric Absolute Value"
                                value={form.metricAbsoluteValue || ""}
                                onChange={handleChangeSearch}
                            />
                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 mt-5">
                            <input onChange={(e) => {
                                onChangeProfile(e)
                            }} className="BtnAccount me-2" type="file"/>
                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 m-5">
                            <button onClick={() => {
                                AddUpdate()
                            }} className="butAUAccount">{id ? "Update Data Entry" : "Add Data Entry"} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataEntryAE;
