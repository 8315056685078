import React, {useEffect, useState} from "react";
import {numberWithCommas} from "../../../../Common/Tools";
import {DashboardGetAccountProjects, DashboardGetProjectMetrics} from "../../../../Api/Services";
import './MetricList.css'
import {useNavigate} from "react-router-dom";
import icon from '../../../../assets/Icon/Money, Coins.png'

function MetricList(props) {
    const [Projects, setProjects] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();


    const start = async () => {
        try {
            const DashboardGetProjectMetricsData = await DashboardGetProjectMetrics(props.projectId)
            if (DashboardGetProjectMetricsData.status && DashboardGetProjectMetricsData.status === 200) {
                setProjects(DashboardGetProjectMetricsData.data.data);
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )
    return (


        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                    {Projects.map(iteam => (
                        <div onClick={() => {
                            navigate("/Panel/Dashboard/Metric/" + iteam.metricId);
                        }} className="element d-flex flex-column m-2 p-3 pb-4 GvexAccountsRadio boxShadow">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="bg-imgProject p-2">
                                    <img
                                        width="25px"
                                        height="auto"
                                        src={icon}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-end flex-column">
                                    <p className="card-category-text">
                                        {iteam.metricName}
                                    </p>
                                    <p className="card-category-value">
                                        $ {numberWithCommas(iteam.sumSavingValue)}
                                    </p>
                                </div>
                            </div>
                            {/*<div className="d-flex justify-content-center align-items-start flex-column">*/}
                            {/*    <div>*/}
                            {/*            <span className="hardSoft me-2">*/}
                            {/*                  Hard Saving*/}
                            {/*            </span>*/}
                            {/*        <span className="hardValue">*/}
                            {/*              ${numberWithCommas(iteam.sumHardSavingMetrics)}*/}
                            {/*          </span>*/}

                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*            <span className="hardSoft me-2">*/}
                            {/*                Soft Saving*/}
                            {/*            </span>*/}
                            {/*        <span className="SoftValue">*/}
                            {/*              ${numberWithCommas(iteam.sumSoftSavingMetrics)}*/}
                            {/*          </span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                    ))}
                </div>
            </div>
        </div>
    );
}

export default MetricList;
