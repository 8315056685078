import "./Approval.css";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CompanyList, GetDataEntrySearch, MetricList, UserList} from "../../Api/Services";
import Select from "../InputCus/Select/Select";
import InputCus from "../InputCus/Text/InputCus";
import imgDoc from "../../assets/Icon/Document, Content.png";


function Approval() {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [DataDropDownAccount, setDataDropDownAccount] = useState([]);
    const [DataDropDownMetric, setDataDropDownMetric] = useState([]);
    const [dataDropDownUser, stataDropDownUser] = useState([])
    const [Search, setSearch] = useState({})
    const [FormIdentity, setFormIdentity] = useState();

    const navigate = useNavigate();

    const start = async () => {
        try {
            setFormIdentity(JSON.parse(localStorage.getItem('Identity')));
            const GetDataEntrySearchData = await GetDataEntrySearch(
                {
                    approverById: 0,
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetDataEntrySearchData.status && GetDataEntrySearchData.status === 200) {
                setData(GetDataEntrySearchData.data.data.dataList)

                const CompanyListData = await CompanyList({})
                if (CompanyListData.status && CompanyListData.status === 200) {
                    let c = [];
                    CompanyListData.data.data.forEach((e) => {
                        c.push({value: e.id, title: e.description})
                    })
                    setDataDropDownAccount(c)
                    const MetricListData = await MetricList({})
                    if (MetricListData.status && MetricListData.status === 200) {
                        let b = [];
                        MetricListData.data.data.forEach((e) => {
                            b.push({value: e.id, title: e.description})
                        })
                        setDataDropDownMetric(b)
                        const UserListData = await UserList({})
                        if (UserListData.status && UserListData.status === 200) {
                            let a = [];
                            UserListData.data.data.forEach((e) => {
                                a.push({value: e.id, title: e.description})
                            })
                            stataDropDownUser(a)
                        }
                    }
                }

            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    const Clear = async () => {
        setSearch({})
        setLoading(true)
        try {
            const GetDataEntrySearchData = await GetDataEntrySearch(
                {
                    approverById: 0,
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetDataEntrySearchData.status && GetDataEntrySearchData.status === 200) {
                setData(GetDataEntrySearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const Searched = async () => {
        try {
            setLoading(true)
            let searching = {...Search}
            let Paging = {
                approverById: 0,
                gridParameters: {
                    pageNumber: 1,
                    pageSize: 1000
                }
            }
            const GetDataEntrySearchData = await GetDataEntrySearch({...searching, ...Paging})
            if (GetDataEntrySearchData.status && GetDataEntrySearchData.status === 200) {
                setData(GetDataEntrySearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const Edite = (id) => {
        navigate("/Panel/Approval/ApprovalDetails/" + id);
    }
    const handleChangeSearch = (e) => {
        const newSearch = {...Search};
        newSearch[e.target.id] = e.target.value;
        setSearch(newSearch);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column element p-5">
                    <div className="d-flex justify-content-startalign-items-center w-100">
                        <span className="AccountsTitle">List Of Data Entry</span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <Select
                            type="text"
                            name="companyId"
                            id="companyId"
                            text="Account"
                            value={Search.companyId || ""}
                            onChange={handleChangeSearch}
                            option={DataDropDownAccount}
                        />
                        <InputCus
                            type="date"
                            name="startDate"
                            id="startDate"
                            text="Date"
                            value={Search.startDate || ""}
                            onChange={handleChangeSearch}
                        />
                        <Select
                            type="text"
                            name="metricId"
                            id="metricId"
                            text="Metric"
                            value={Search.metricId || ""}
                            onChange={handleChangeSearch}
                            option={DataDropDownMetric}
                        />
                        <InputCus
                            type="date"
                            name="timeline"
                            id="timeline"
                            text="Timeline"
                            value={Search.timeline || ""}
                            onChange={handleChangeSearch}
                        />
                        <Select
                            type="text"
                            name="approverByName"
                            id="approverByName"
                            text="DataEntry Operator"
                            value={Search.approverByName || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownUser}
                        />
                        <InputCus
                            type="text"
                            name="dataProvider"
                            id="dataProvider"
                            text="Data Provider"
                            value={Search.dataProvider || ""}
                            onChange={handleChangeSearch}
                        />
                        <InputCus
                            type="text"
                            name="dataSource"
                            id="dataSource"
                            text="Data Source"
                            value={Search.dataSource || ""}
                            onChange={handleChangeSearch}
                        />
                    </div>
                    <div className="d-flex justify-content-start align-items-center w-100">
                        <button onClick={() => {
                            Searched()
                        }} className="m-3 btnSearch">Search
                        </button>
                        <button onClick={() => {
                            Clear()
                        }} className="btnCancel">Clear
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 p-2 tableC">
                        <table className="table">
                            <thead>
                            <tr className="trTable">
                                <th>Account</th>
                                <th>date</th>
                                <th>Metric</th>
                                <th>Timeline</th>
                                <th>DataEntry Operator</th>
                                <th>Data Provider</th>
                                <th>Data Source</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item, key) =>
                                <tr key={key}>
                                    <td>
                                        {item.companyName}
                                    </td>
                                    <td>
                                        {item.startDate}
                                    </td>
                                    <td>
                                        {item.metricName}
                                    </td>
                                    <td>
                                        {item.timeline}
                                    </td>

                                    <td>
                                        {item.approverByName}
                                    </td>
                                    <td>
                                        {item.dataProvider}
                                    </td>
                                    <td>
                                        {item.dataSource}
                                    </td>
                                    <td className="td-actions">
                                        {FormIdentity && FormIdentity.DataEntry.Update &&
                                            <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                  title="Edite Approve ...">

                                            <img className="me-2" src={imgDoc} alt=" Edite Approve ..." onClick={() => {
                                                Edite(item.id)
                                            }}/>
                                            </span>}
                                    </td>

                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Approval;
