import "./MetricsAE.css";
import InputCus from "../InputCus/Text/InputCus";
import {useEffect, useState} from "react";
import {
    AddMetrics,
    CompanyList,
    GetCompanyUsers,
    GetMetricSearch, GetMetricType, GetMonitoringPeriod,
    ProjectList, UpdateMetrics,
} from "../../Api/Services";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import AdaptorDTO from "../../Common/AdaptorDTO";
import Select from "../InputCus/Select/Select";

const ModelMetric = {
    title: "",
    strategyInitiative: "",
    metricDescription: "",
    metricTypeId: 0,
    operationalDefinitionId: 0,
    baseLine: 0,
    unit: "",
    standardValue: 0,
    baselineTimeline: "",
    formula: "",
    isIntagible: false,
    reportingTimelineId: 0,
    ownerId: 0,
    approvedBy: "",
    monitoringPeriodId: 0,
    dataProvider: "",
    dataSource: "",
    confidenceLevel: 0,
    projectProportionateRate: 0,
    coefficient1: 0,
    coefficient2: 0,
    projectId: 0
}

function MetricsAE() {
    const [isLoading, setLoading] = useState(true);
    const [form, setForm] = useState({});
    const [Company, setCompany] = useState();
    const [ListMetricType, setListMetricType] = useState([]);
    const [ListMonitoringPeriod, setListMonitoringPeriod] = useState([]);
    const [ListUser, setListUser] = useState([]);
    const [ListCompany, setListCompany] = useState([]);
    const [ListProject, setListProject] = useState([]);


    const navigate = useNavigate();

    let {id} = useParams();
    const start = async () => {
        try {
            if (id) {
                const GetMetricSearchData = await GetMetricSearch({id: id})
                if (GetMetricSearchData.status && GetMetricSearchData.status === 200) {
                    setForm(AdaptorDTO({...ModelMetric}, GetMetricSearchData.data.data.dataList[0]));
                    setCompany(GetMetricSearchData.data.data.dataList[0].companyId)
                    const ProjectListData = await ProjectList({companyId: GetMetricSearchData.data.data.dataList[0].companyId})
                    if (ProjectListData.status && ProjectListData.status === 200) {
                        let g = [];
                        ProjectListData.data.data.forEach((e) => {
                            g.push({value: e.id, title: e.description})
                        })
                        setListProject(g)
                        const GetCompanyUsersData = await GetCompanyUsers(GetMetricSearchData.data.data.dataList[0].companyId)
                        if (GetCompanyUsersData.status && GetCompanyUsersData.status === 200) {
                            let gw = [];
                            GetCompanyUsersData.data.data.forEach((e) => {
                                gw.push({value: e.id, title: e.userName})
                            })
                            setListUser(gw)
                        }
                    }
                }
            }

            const GetMetricTypeData = await GetMetricType()
            if (GetMetricTypeData.status && GetMetricTypeData.status === 200) {
                let a = [];
                GetMetricTypeData.data.data.forEach((e) => {
                    a.push({value: e.id, title: e.latinName})
                })
                setListMetricType(a)
                const GetMonitoringPeriodData = await GetMonitoringPeriod()
                if (GetMonitoringPeriodData.status && GetMonitoringPeriodData.status === 200) {
                    let b = [];
                    GetMetricTypeData.data.data.forEach((e) => {
                        b.push({value: e.id, title: e.latinName})
                    })
                    setListMonitoringPeriod(b);
                    const CompanyListData = await CompanyList({})
                    if (CompanyListData.status && CompanyListData.status === 200) {
                        let c = [];
                        CompanyListData.data.data.forEach((e) => {
                            c.push({value: e.id, title: e.description})
                        })
                        setListCompany(c);
                    }
                }

            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    const handleAccount = async (e) => {
        setCompany(e.target.value);
        if (e.target.value !== undefined) {
            if (e.target.value === "-1") {
                setListProject([])
                setListUser([])
            } else {
                try {
                    setLoading(true)
                    const ProjectListData = await ProjectList({companyId: e.target.value})
                    if (ProjectListData.status && ProjectListData.status === 200) {
                        let g = [];
                        ProjectListData.data.data.forEach((e) => {
                            g.push({value: e.id, title: e.description})
                        })
                        setListProject(g)
                        const GetCompanyUsersData = await GetCompanyUsers(e.target.value)
                        if (GetCompanyUsersData.status && GetCompanyUsersData.status === 200) {
                            let gw = [];
                            GetCompanyUsersData.data.data.forEach((e) => {
                                gw.push({value: e.id, title: e.userName})
                            })
                            setListUser(gw)
                        }
                    }
                } catch (error) {
                    console.error("Error during login:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
    }

    const AddUpdate = async () => {
        let validation = true
        if (form.approvedBy === "") {
            toast.error('Fill in the approvedBy !')
            validation = false
        }
        if (form.confidenceLevel === "") {
            toast.error('Fill in the Confidence Level !')
            validation = false
        }
        if (form.projectProportionateRate === "") {
            toast.error('Fill in the Company Proportionate Rate !')
            validation = false
        }
        if (form.coefficient1 === "") {
            toast.error('Fill in the Coefficient 1 !')
            validation = false
        }
        if (form.coefficient2 === "") {
            toast.error('Fill in the Coefficient 2 !')
            validation = false
        }
        if (validation) {
            setLoading(true)
            try {
                if (id) {
                    const UpdateMetricsData = await UpdateMetrics(form, id)
                    if (UpdateMetricsData.status && UpdateMetricsData.status === 200) {
                        toast.success('Your information has been update!');
                        navigate("/Panel/Metrics");
                    }
                } else {
                    const AddMetricsData = await AddMetrics(form)
                    if (AddMetricsData.status && AddMetricsData.status === 200) {
                        toast.success('Your information has been add!');
                        navigate("/Panel/Metrics");
                    }
                }
            } catch (error) {
                console.error("Error during login:", error);
            } finally {
                setLoading(false);

            }
        }
    }

    function handleChangeSearch(e) {
        const newForm = {...form};
        newForm[e.target.id] = e.target.value;
        setForm(newForm);
    }

    function handleChangeCheck(e) {
        const newForm = {...form};
        newForm.isIntagible = e.target.checked;
        setForm(newForm);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-between align-items-start p-5">
                    <div className="d-flex justify-content-start align-items-center flex-column element w-100 p-5">
                        <div className="d-flex justify-content-start align-items-center flex-wrap  ">
                            <Select
                                type="text"
                                name="companyId"
                                id="companyId"
                                text="Account"
                                value={Company || ""}
                                onChange={handleAccount}
                                option={ListCompany}
                            />
                            <Select
                                type="text"
                                name="projectId"
                                id="projectId"
                                text="Project"
                                value={form.projectId || ""}
                                onChange={handleChangeSearch}
                                option={ListProject}
                            />
                            <InputCus
                                type="text"
                                name="strategyInitiative"
                                id="strategyInitiative"
                                text="Strategy/Initiative"
                                value={form.strategyInitiative || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="title"
                                id="title"
                                text="Metric"
                                value={form.title || ""}
                                onChange={handleChangeSearch}
                            />
                            <Select
                                type="text"
                                name="metricTypeId"
                                id="metricTypeId"
                                text="Metric Type"
                                value={form.metricTypeId || ""}
                                onChange={handleChangeSearch}
                                option={ListMetricType}
                            />
                            <InputCus
                                type="number"
                                name="operationalDefinitionId"
                                id="operationalDefinitionId"
                                text="Operational Definition"
                                value={form.operationalDefinitionId || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="number"
                                name="baseLine"
                                id="baseLine"
                                text="BaseLine"
                                value={form.baseLine || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="unit"
                                id="unit"
                                text="Unit"
                                value={form.unit || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="number"
                                name="standardValue"
                                id="standardValue"
                                text="Standard Value"
                                value={form.standardValue || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="date"
                                name="baselineTimeline"
                                id="baselineTimeline"
                                text="Baseline Timeline"
                                value={form.baselineTimeline || ""}
                                onChange={handleChangeSearch}
                            />
                            <div className="d-flex justify-content-center align-items-start form-item pt-3">
                                <div className="d-flex justify-content-center align-items-center">
                                    <input className="me-2" type="checkbox" id="isIntagible" onChange={(e) => handleChangeCheck(e)}
                                           checked={form.isIntagible}/>
                                    <span>Is Intagible</span>
                                </div>
                            </div>
                            <InputCus
                                type="text"
                                name="metricDescription"
                                id="metricDescription"
                                text="Description"
                                value={form.metricDescription || ""}
                                onChange={handleChangeSearch}
                            />
                            <Select
                                type="text"
                                name="ownerId"
                                id="ownerId"
                                text="Owner"
                                value={form.ownerId || ""}
                                onChange={handleChangeSearch}
                                option={ListUser}
                            />
                            <Select
                                type="text"
                                name="approvedBy"
                                id="approvedBy"
                                text="Approved By"
                                value={form.approvedBy || ""}
                                onChange={handleChangeSearch}
                                option={ListUser}
                            />
                            <Select
                                type="text"
                                name="monitoringPeriodId"
                                id="monitoringPeriodId"
                                text="Monitoring Period"
                                value={form.monitoringPeriodId || ""}
                                onChange={handleChangeSearch}
                                option={ListMonitoringPeriod}
                            />
                            <InputCus
                                type="text"
                                name="dataProvider"
                                id="dataProvider"
                                text="Data Provider"
                                value={form.dataProvider || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="dataSource"
                                id="dataSource"
                                text="Data Source"
                                value={form.dataSource || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="number"
                                name="confidenceLevel"
                                id="confidenceLevel"
                                text="Confidence Level"
                                value={form.confidenceLevel || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="number"
                                name="projectProportionateRate"
                                id="projectProportionateRate"
                                text="Project Proportionate Rate"
                                value={form.projectProportionateRate || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="number"
                                name="coefficient1"
                                id="coefficient1"
                                text="Coefficient 1"
                                value={form.coefficient1 || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="number"
                                name="coefficient2"
                                id="coefficient2"
                                text="Coefficient 2"
                                value={form.coefficient2 || ""}
                                onChange={handleChangeSearch}
                            />

                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 m-5">
                            <button onClick={() => {
                                AddUpdate()
                            }} className="butAUAccount">{id ? "Update Metric" : "Add Metric"} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MetricsAE;
