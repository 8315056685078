import "./Users.css";
import InputCus from "../InputCus/Text/InputCus";
import {useEffect, useState} from "react";
import Select from "../InputCus/Select/Select";
import {CompanyList, DeleteUser, GetJobTitle, GetUserSearch, UserList} from "../../Api/Services";
import imgDelete from "../../assets/Icon/Delete, Disabled.png"
import imgDoc from "../../assets/Icon/Document, Content.png"
import {confirmAlert} from 'react-confirm-alert';
import imgPlus from "../../assets/Icon/pluse.png"
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

function Users() {
    const [Search, setSearch] = useState({})
    const [dataUser, setDataUser] = useState([])
    const [drapeDownCompanies, setDrapeDownCompanies] = useState([])
    const [dataDropDownUser, setDataDropDownUser] = useState([])
    const [FormIdentity, setFormIdentity] = useState();
    const [isLoading, setLoading] = useState(true);
    const [dataDropDownJob, setDataDropDownJob] = useState([])
    const navigate = useNavigate();

    const start = async () => {
        try {
            setFormIdentity(JSON.parse(localStorage.getItem('Identity')));
            const GetUserSearchData = await GetUserSearch(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetUserSearchData.status && GetUserSearchData.status === 200) {
                setDataUser(GetUserSearchData.data.data.dataList);
                const CompanyListData = await CompanyList({})
                if (CompanyListData.status && CompanyListData.status === 200) {
                    let a = [];
                    CompanyListData.data.data.forEach((e) => {
                        a.push({value: e.id, title: e.description})
                    })
                    setDrapeDownCompanies(a)
                    const UserListData = await UserList({})
                    if (UserListData.status && UserListData.status === 200) {
                        let c = [];
                        UserListData.data.data.forEach((e) => {
                            c.push({value: e.id, title: e.description})
                        })
                        setDataDropDownUser(c)

                        const GetJobTitleData = await GetJobTitle({})
                        if (GetJobTitleData.status && GetJobTitleData.status === 200) {
                            let b = [];
                            GetJobTitleData.data.data.forEach((e) => {
                                b.push({value: e.id, title: e.latinName})
                            })
                            setDataDropDownJob(b);

                        }
                    }
                }
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    const Clear = async () => {
        setSearch({})
        setLoading(true)
        try {
            const GetUserSearchData = await GetUserSearch(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetUserSearchData.status && GetUserSearchData.status === 200) {
                setDataUser(GetUserSearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const Searched = async () => {
        try {
            setLoading(true)
            let searching = {...Search}
            let Paging = {
                gridParameters: {
                    pageNumber: 1,
                    pageSize: 1000
                }
            }
            const GetUserSearchData = await GetUserSearch({...searching, ...Paging})
            if (GetUserSearchData.status && GetUserSearchData.status === 200) {
                setDataUser(GetUserSearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const confirmDelete = (e) => {
        confirmAlert({
            title: 'Delete User',
            message: 'Are You Sure To Do Remove User ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteItem(e)
                },
                {
                    label: 'No',
                }
            ]
        });
    };
    const DeleteItem = async (e) => {

        try {
            setLoading(true)
            const DeleteUserData = await DeleteUser(e)
            if (DeleteUserData.status && DeleteUserData.status === 200) {
                toast.success('Your information has been delete!');
                await start();
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }

    }
    const Add = () => {
        navigate("/Panel/Users/Add");
    }
    const Edite = (id) => {
        navigate("/Panel/Users/Edite/" + id);
    }

    const handleChangeSearch = (e) => {
        const newSearch = {...Search};
        newSearch[e.target.id] = e.target.value;
        setSearch(newSearch);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column element p-5">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <span className="AccountsTitle">Users</span>
                        {FormIdentity && FormIdentity.User.Create && <img onClick={() => {
                            Add()
                        }} src={imgPlus} alt=""/>}
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <Select
                            type="text"
                            name="companyId"
                            id="companyId"
                            text="Account"
                            value={Search.companyId || ""}
                            onChange={handleChangeSearch}
                            option={drapeDownCompanies}
                        />
                        <Select
                            type="text"
                            name="userId"
                            id="userId"
                            text="UserName"
                            value={Search.userId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownUser}
                        />
                        <Select
                            type="text"
                            name="jobTitleId"
                            id="jobTitleId"
                            text="Job Title"
                            value={Search.jobTitleId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownJob}
                        />
                        <InputCus
                            type="text"
                            name="email"
                            id="email"
                            text="Email"
                            value={Search.email || ""}
                            onChange={handleChangeSearch}
                        />
                        <InputCus
                            type="text"
                            name="Cell"
                            id="Cell"
                            text="Cell"
                            value={Search.Cell || ""}
                            onChange={handleChangeSearch}

                        />

                    </div>
                    <div className="d-flex justify-content-start align-items-center w-100">
                        <button onClick={() => {
                            Searched()
                        }} className="m-3 btnSearch">Search
                        </button>
                        <button onClick={() => {
                            Clear()
                        }} className="btnCancel">Clear
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 p-2 tableC">
                        <table className="table">
                            <thead>
                            <tr className="trTable">
                                <th>Account</th>
                                <th>UserName</th>
                                <th>Job Title</th>
                                <th>Email</th>
                                <th>Cell</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {dataUser.map((item, key) =>
                                <tr key={key}>
                                    <td>
                                        {item.companyName}
                                    </td>
                                    <td>
                                        {item.userName}
                                    </td>
                                    <td>
                                        {item.jobTitleName}
                                    </td>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        {item.cellNumber}
                                    </td>

                                    <td className="td-actions">

                                        {FormIdentity && FormIdentity.User.Delete &&
                                            <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                  title="Remove User ...">
                                                <img className="me-2" src={imgDelete} alt="Remove User ..."
                                                     onClick={() => {
                                                         confirmDelete(item.id)
                                                     }}/>
                                            </span>}
                                        {FormIdentity && FormIdentity.User.Update &&
                                            <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                  title="Edite User ...">

                                            <img src={imgDoc} alt=" Edite User ..." onClick={() => {
                                                Edite(item.id)
                                            }}/>
                                            </span>}
                                    </td>

                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users;

