function Home() {
    return (
            <div className={"stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column element p-5">
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <span className="AccountsTitle">Home</span>
                    </div>
                </div>
            </div>
    )
}

export default Home;
