import './DetProject.css'
import React, {useEffect, useState} from "react";
import gicon from "../../../../assets/Icon/Statistics, Analytics,Arrow, Up, Circle,.png"
import {
    GetHardSoftSavingProject
} from "../../../../Api/Services";
import {numberWithCommas} from "../../../../Common/Tools";

function DetProject(props) {
    const [projectsMonetary, setprojectsMonetary] = useState();
    const [HardSoft, setHardSoft] = useState();
    const [isLoading, setLoading] = useState(true);


    const start = async () => {
        try {
            console.log(props.projectId)
            const GetHardSoftSavingProjectData = await GetHardSoftSavingProject(props.projectId)
            if (GetHardSoftSavingProjectData.status && GetHardSoftSavingProjectData.status === 200) {
                console.log(GetHardSoftSavingProjectData)
                setprojectsMonetary(GetHardSoftSavingProjectData.data.data[0].sumSavingValue)
                setHardSoft(GetHardSoftSavingProjectData.data.data[0]);
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    return (

        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="d-flex justify-content-start align-items-center w-100">
                    <div className="d-flex flex-column GvexMonetaryRadi p-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className='GvexMonetaryRadi-icon d-flex justify-content-center align-items-center'>
                                <img src={gicon}/>
                            </div>
                            <div className='d-flex justify-content-center align-items-end flex-column'>
                                <span
                                    className="name">{props.projectsName ? props.projectsName : "Saving"}</span>
                                <span
                                    className="value">{projectsMonetary ? "$" + numberWithCommas(projectsMonetary) : "projectsMonetary"}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className='d-flex justify-content-center align-items-center'>
                                <span className="nameProjects me-2">Hard Savings</span>
                                <span
                                    className="valueProjects">{HardSoft ? "$" + numberWithCommas(HardSoft.sumHardSavingMetrics) :0}</span>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <span className="nameProjects me-2">Soft Savings</span>
                                <span
                                    className="valueProjectsSuss">{HardSoft ? "$" + numberWithCommas(HardSoft.sumSoftSavingMetrics) : 0}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetProject;
