import "./ApprovalDetails.css";
import InputCus from "../InputCus/Text/InputCus";
import {useEffect, useState} from "react";
import {
    AddDataEnters,
    AddMetrics,
    CompanyList, DeleteMetric,
    GetCompanyUsers,
    GetDataEntrySearch,
    MetricList,
    ProjectList, UpdateDataEnters,
    UpdateMetrics,
} from "../../Api/Services";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Select from "../InputCus/Select/Select";
import AdaptorDTO from "../../Common/AdaptorDTO";
import {confirmAlert} from "react-confirm-alert";

const ModelDataEntry = {
    name: "",
    metricId: 0,
    startDate: "0-0-1",
    timeline: "0-0-20",
    dataProvider: "",
    dataSource: "",
    approverById: 0,
    metricAbsoluteValue: 0,
    file: "",
}

function ApprovalDetails() {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [form, setForm] = useState({});

    const navigate = useNavigate();

    let {id} = useParams();
    const start = async () => {
        try {
            const GetDataEntrySearchData = await GetDataEntrySearch({id: id})
            if (GetDataEntrySearchData.status && GetDataEntrySearchData.status === 200) {
                setData(AdaptorDTO({...ModelDataEntry}, GetDataEntrySearchData.data.data.dataList[0]));
                setForm(GetDataEntrySearchData.data.data.dataList[0]);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )
    const confirmApprove = (type) => {
        confirmAlert({
            title: 'Approve',
            message: 'Are u sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ApproveReject(type)
                },
                {
                    label: 'No',
                }
            ]
        });
    };
    const ApproveReject = async (type) => {

        try {
            setLoading(true)
            const newForm = {...data};

            newForm.approverById = type == 1 ? form.approverBy : -1;
            const UpdateDataEntersData = await UpdateDataEnters(newForm, id)
            if (UpdateDataEntersData.status && UpdateDataEntersData.status === 200) {
                toast.success('Your information has been' + type == 1 ? "Approve !" : "Reject !");
                navigate("/Panel/Approval");

            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }

    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-between align-items-start p-5">
                    <div className="d-flex justify-content-start align-items-center flex-column element w-100 p-5">
                        <div className="d-flex justify-content-start align-items-center flex-wrap  ">
                            <InputCus
                                disabled={true}
                                type="text"
                                text="Account"
                                value={form.companyName || ""}
                            />
                            <InputCus
                                disabled={true}
                                type="text"
                                text="Date"
                                value={form.startDate || ""}
                            />
                            <InputCus
                                disabled={true}
                                type="text"
                                text="Metric"
                                value={form.metricName || ""}
                            />
                            <InputCus
                                disabled={true}
                                type="text"
                                text="Timeline"
                                value={form.timeline || ""}
                            />
                            <InputCus
                                disabled={true}
                                type="text"
                                text="Data Entry Operator"
                                value={form.dataEntryYear + "-" + form.dataEntryMonth}
                            />
                            <InputCus
                                disabled={true}
                                type="text"
                                text="Data Provider"
                                value={form.dataProvider}
                            />
                            <InputCus
                                disabled={true}
                                type="text"
                                text="Data Source"
                                value={form.dataSource}
                            />
                            <InputCus
                                disabled={true}
                                type="text"
                                text="Metric Absolute Value"
                                value={form.metricAbsoluteValue}
                            />

                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 m-5">
                            <button onClick={() => {
                                confirmApprove(1)
                            }} className="butAUAccount bg-success">Approve
                            </button>
                            <button onClick={() => {
                                confirmApprove(0)
                            }} className="butAUAccount bg-danger">Reject
                            </button>
                            <button onClick={() => {
                                navigate("/Panel/Approval");
                            }} className="butAUAccount bg-secondary">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApprovalDetails;
