import React, {useEffect, useState} from "react";
import {numberWithCommas} from "../../../../Common/Tools";
import {DashboardGetAccountProjects} from "../../../../Api/Services";
import './ProjectList.css'
import {useNavigate} from "react-router-dom";
import icon from '../../../../assets/Icon/Money, Coins.png'

function ProjectList(props) {
    const [Projects, setProjects] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();


    const start = async () => {
        try {
            const DashboardGetAccountProjectsData = await DashboardGetAccountProjects(props.companyId)
            if (DashboardGetAccountProjectsData.status && DashboardGetAccountProjectsData.status === 200) {
                setProjects(DashboardGetAccountProjectsData.data.data);
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )
    return (


        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                    {Projects.map((iteam,key) => (
                        <div key={key} onClick={() => {
                            navigate("/Panel/Dashboard/Project/" + iteam.projectId);
                        }} className="element d-flex flex-column m-2 p-2 GvexAccountsRadio boxShadow">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="bg-imgProject p-2">
                                    <img
                                        width="25px"
                                        height="auto"
                                        src={icon}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-end flex-column">
                                    <p className="card-category-text">
                                        {iteam.projectName}
                                    </p>
                                    <p className="card-category-value">
                                        $ {numberWithCommas(iteam.sumSavingValue)}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-start flex-column">
                                <div>
                                        <span className="hardSoft me-2">
                                              Hard Savings
                                        </span>
                                    <span className="hardValue">
                                          ${numberWithCommas(iteam.sumHardSavingMetrics)}
                                      </span>

                                </div>
                                <div>
                                        <span className="hardSoft me-2">
                                            Soft Savings
                                        </span>
                                    <span className="SoftValue">
                                          ${numberWithCommas(iteam.sumSoftSavingMetrics)}
                                      </span>
                                </div>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjectList;
