const Identity = {
    Dashboard: {
        DashboardGetMetricMonetary: false,
        DashboardGetMetricTotalGainedValue: false,
        DashboardGetProjectMonetary: false,
        DashboardGetProjectMetrics: false,
        DashboardGetProjectTotalGainedValue: false,
        DashboardGetMetricPercentageGainedValue: false,
        DashboardGetMetricMonthlyGainedValue: false,
        DashboardGetSumProjectsValue: false,
        DashboardGetAccountMonetary: false,
        DashboardGetAccountProjects: false,
        DashboardGetAccountTotalGainedValue: false,
        DashboardGetProjectMonthlyGainedValue: false,
        DashboardGetProjectPercentageGainedValue: false,
        DashboardGetGvexMonetary: false,
        DashboardGetGvexAccounts: false,
        DashboardGetGVEXROIPortfolio: false,
        DashboardGetGvexMonthlyGainedValue: false,
        DashboardGetGvexAccountPercentageGainedValue: false,
    },
    UserDashboard: {
        UserDashboardGetMetricMonetary: false,
        UserDashboardGetMetricTotalGainedValue: false,
        UserDashboardGetProjectMonetary: false,
        UserDashboardGetProjectMetrics: false,
        UserDashboardGetProjectTotalGainedValue: false,
        UserDashboardGetMetricPercentageGainedValue: false,
        UserDashboardGetMetricMonthlyGainedValue: false,
        UserDashboardGetSumProjectsValue: false,
        UserDashboardGetAccountMonetary: false,
        UserDashboardGetAccountProjects: false,
        UserDashboardGetAccountTotalGainedValue: false,
        UserDashboardGetProjectMonthlyGainedValue: false,
        UserDashboardGetProjectPercentageGainedValue: false,
        UserDashboardGetGvexMonetary: false,
        UserDashboardGetGvexAccounts: false,
        UserDashboardGetGVEXROIPortfolio: false,
        UserDashboardGetGvexMonthlyGainedValue: false,
        UserDashboardGetGvexAccountPercentageGainedValue: false,
    },
    Account: {
        Create: false,
        Read: false,
        Update: false,
        Delete: false,
        Filter:false
    },
    User: {
        Create: false,
        Read: false,
        Update: false,
        Delete: false,
        Filter:false
    },
    Project: {
        Create: false,
        Read: false,
        Update: false,
        Delete: false,
        Filter:false
    },
    Metric: {
        Create: false,
        Read: false,
        Update: false,
        Delete: false,
        Filter:false
    },
    DataEntry: {
        Create: false,
        Read: false,
        Update: false,
        Delete: false,
        Filter:false
    },
    Activities: {
        Read: false,
    }
}

export default function ConvertIdentity(array) {
    let Model = {...Identity}
    Model.User.Create = array.find((Identity) => Identity.claimValue === "AddUser").isSelected
    Model.User.Delete = array.find((Identity) => Identity.claimValue === "DeleteUser").isSelected
    Model.User.Read = array.find((Identity) => Identity.claimValue === "GetUser").isSelected
    Model.User.Filter = array.find((Identity) => Identity.claimValue === "FilterUser").isSelected
    Model.User.Update = array.find((Identity) => Identity.claimValue === "EditUser").isSelected

    Model.Account.Create = array.find((Identity) => Identity.claimValue === "AddAccount").isSelected
    Model.Account.Delete = array.find((Identity) => Identity.claimValue === "DeleteAccount").isSelected
    Model.Account.Read = array.find((Identity) => Identity.claimValue === "ViewAccount").isSelected
    Model.Account.Filter = array.find((Identity) => Identity.claimValue === "FilterAccount").isSelected
    Model.Account.Update = array.find((Identity) => Identity.claimValue === "EditAccount").isSelected

    Model.Activities.Read = array.find((Identity) => Identity.claimValue === "ViewActivities").isSelected

    Model.DataEntry.Create = array.find((Identity) => Identity.claimValue === "AddDataEntry").isSelected
    Model.DataEntry.Delete = array.find((Identity) => Identity.claimValue === "DeleteDataEntry").isSelected
    Model.DataEntry.Read = array.find((Identity) => Identity.claimValue === "GetDataEntry").isSelected
    Model.DataEntry.Filter = array.find((Identity) => Identity.claimValue === "FilterDataEntry").isSelected
    Model.DataEntry.Update = array.find((Identity) => Identity.claimValue === "EditDataEntry").isSelected

    Model.Metric.Create = array.find((Identity) => Identity.claimValue === "AddMetric").isSelected
    Model.Metric.Delete = array.find((Identity) => Identity.claimValue === "DeleteMetric").isSelected
    Model.Metric.Read = array.find((Identity) => Identity.claimValue === "ViewMetric").isSelected
    Model.Metric.Filter = array.find((Identity) => Identity.claimValue === "FilterMetric").isSelected
    Model.Metric.Update = array.find((Identity) => Identity.claimValue === "EditMetric").isSelected

    Model.Project.Create = array.find((Identity) => Identity.claimValue === "AddProject").isSelected
    Model.Project.Delete = array.find((Identity) => Identity.claimValue === "DeleteProject").isSelected
    Model.Project.Read = array.find((Identity) => Identity.claimValue === "GetProject").isSelected
    Model.Project.Filter = array.find((Identity) => Identity.claimValue === "FilterProject").isSelected
    Model.Project.Update = array.find((Identity) => Identity.claimValue === "EditProject").isSelected

    Model.Dashboard.DashboardGetMetricMonetary = array.find((Identity) => Identity.claimValue === "DashboardGetMetricMonetary").isSelected
    Model.Dashboard.DashboardGetMetricTotalGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetMetricTotalGainedValue").isSelected
    Model.Dashboard.DashboardGetProjectMonetary = array.find((Identity) => Identity.claimValue === "DashboardGetProjectMonetary").isSelected
    Model.Dashboard.DashboardGetProjectMetrics = array.find((Identity) => Identity.claimValue === "DashboardGetProjectMetrics").isSelected
    Model.Dashboard.DashboardGetProjectTotalGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetProjectTotalGainedValue").isSelected
    Model.Dashboard.DashboardGetMetricPercentageGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetMetricPercentageGainedValue").isSelected
    Model.Dashboard.DashboardGetMetricMonthlyGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetMetricMonthlyGainedValue").isSelected
    Model.Dashboard.DashboardGetSumProjectsValue = array.find((Identity) => Identity.claimValue === "DashboardGetSumProjectsValue").isSelected
    Model.Dashboard.DashboardGetAccountMonetary = array.find((Identity) => Identity.claimValue === "DashboardGetAccountMonetary").isSelected
    Model.Dashboard.DashboardGetAccountProjects = array.find((Identity) => Identity.claimValue === "DashboardGetAccountProjects").isSelected
    Model.Dashboard.DashboardGetAccountTotalGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetAccountTotalGainedValue").isSelected
    Model.Dashboard.DashboardGetProjectMonthlyGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetProjectMonthlyGainedValue").isSelected
    Model.Dashboard.DashboardGetProjectPercentageGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetProjectPercentageGainedValue").isSelected
    Model.Dashboard.DashboardGetGvexMonetary = array.find((Identity) => Identity.claimValue === "DashboardGetGvexMonetary").isSelected
    Model.Dashboard.DashboardGetGvexAccounts = array.find((Identity) => Identity.claimValue === "DashboardGetGvexAccounts").isSelected
    Model.Dashboard.DashboardGetGVEXROIPortfolio = array.find((Identity) => Identity.claimValue === "DashboardGetGVEXROIPortfolio").isSelected
    Model.Dashboard.DashboardGetGvexMonthlyGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetGvexMonthlyGainedValue").isSelected
    Model.Dashboard.DashboardGetGvexAccountPercentageGainedValue = array.find((Identity) => Identity.claimValue === "DashboardGetGvexAccountPercentageGainedValue").isSelected

    Model.UserDashboard.UserDashboardGetMetricMonetary = array.find((Identity) => Identity.claimValue === "UserDashboardGetMetricMonetary").isSelected
    Model.UserDashboard.UserDashboardGetMetricTotalGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetMetricTotalGainedValue").isSelected
    Model.UserDashboard.UserDashboardGetProjectMonetary = array.find((Identity) => Identity.claimValue === "UserDashboardGetProjectMonetary").isSelected
    Model.UserDashboard.UserDashboardGetProjectMetrics = array.find((Identity) => Identity.claimValue === "UserDashboardGetProjectMetrics").isSelected
    Model.UserDashboard.UserDashboardGetProjectTotalGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetProjectTotalGainedValue").isSelected
    Model.UserDashboard.UserDashboardGetMetricPercentageGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetMetricPercentageGainedValue").isSelected
    Model.UserDashboard.UserDashboardGetMetricMonthlyGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetMetricMonthlyGainedValue").isSelected
    Model.UserDashboard.UserDashboardGetSumProjectsValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetSumProjectsValue").isSelected
    Model.UserDashboard.UserDashboardGetAccountMonetary = array.find((Identity) => Identity.claimValue === "UserDashboardGetAccountMonetary").isSelected
    Model.UserDashboard.UserDashboardGetAccountProjects = array.find((Identity) => Identity.claimValue === "UserDashboardGetAccountProjects").isSelected
    Model.UserDashboard.UserDashboardGetAccountTotalGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetAccountTotalGainedValue").isSelected
    Model.UserDashboard.UserDashboardGetProjectMonthlyGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetProjectMonthlyGainedValue").isSelected
    Model.UserDashboard.UserDashboardGetProjectPercentageGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetProjectPercentageGainedValue").isSelected
    Model.UserDashboard.UserDashboardGetGvexMonetary = array.find((Identity) => Identity.claimValue === "UserDashboardGetGvexMonetary").isSelected
    Model.UserDashboard.UserDashboardGetGvexAccounts = array.find((Identity) => Identity.claimValue === "UserDashboardGetGvexAccounts").isSelected
    Model.UserDashboard.UserDashboardGetGVEXROIPortfolio = array.find((Identity) => Identity.claimValue === "UserDashboardGetGVEXROIPortfolio").isSelected
    Model.UserDashboard.UserDashboardGetGvexMonthlyGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetGvexMonthlyGainedValue").isSelected
    Model.UserDashboard.UserDashboardGetGvexAccountPercentageGainedValue = array.find((Identity) => Identity.claimValue === "UserDashboardGetGvexAccountPercentageGainedValue").isSelected


    return Model
}