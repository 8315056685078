import InputCus from "../InputCus/Text/InputCus";
import {useEffect, useState} from "react";
import {
    AddProjects,
    CompanyList,
    GetProjectSearch,
    GetUserUsername, UpdateProjects,
} from "../../Api/Services";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import AdaptorDTO from "../../Common/AdaptorDTO";
import bg from "../../assets/Image/image 13.png";
import face from "../../assets/Image/face.jpg";
import Select from "../InputCus/Select/Select";

const ModelProject = {
    "projectName": "",
    "startDate": "",
    "endDate": "",
    "projectLead": "",
    "projectSponsor": "",
    "reactionInitialValue": 0,
    "reactionCurrentValue": 0,
    "reactionDescription": "",
    "trainingInitialValue": 0,
    "trainingCurrentValue": 0,
    "trainingDescription": "",
    "applicationInitialValue": 0,
    "applicationCurrentValue": 0,
    "applicationDescription": "",
    "companyId": 0,
}

function ProjectsTraining() {
    const [isLoading, setLoading] = useState(true);
    const [forms, setForm] = useState({})
    const [User, setUser] = useState()

    const navigate = useNavigate();

    let {id} = useParams();
    const start = async () => {
        try {
            const GetProjectSearchData = await GetProjectSearch({id:id})
            if (GetProjectSearchData.status && GetProjectSearchData.status === 200) {
                setForm(AdaptorDTO({...ModelProject},GetProjectSearchData.data.data.dataList[0]));
                const GetUserUsernameData = await GetUserUsername(localStorage.getItem("Username"))
                if (GetUserUsernameData.status && GetUserUsernameData.status === 200) {
                    setUser(GetUserUsernameData.data.data[0]);
                }
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    const AddUpdate = async () => {
        setLoading(true)
        try {
            const UpdateProjectsData = await UpdateProjects(forms, id)
            if (UpdateProjectsData.status && UpdateProjectsData.status === 200) {
                toast.success('Your information has been update!');
                navigate("/Panel/Projects");
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);

        }
    }


    function handleChangeSearch(e) {
        const newForm = {...forms};
        newForm[e.target.id] = e.target.value;
        setForm(newForm);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-between align-items-start p-5">
                    <div className="d-flex justify-content-start align-items-center flex-column element me-5 w-75">
                        <div className="d-flex justify-content-start align-items-center flex-wrap p-5 ">

                            <InputCus
                                type="number"
                                name="trainingInitialValue"
                                id="trainingInitialValue"
                                text="Initial value"
                                value={forms.trainingInitialValue || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="number"
                                name="trainingCurrentValue"
                                id="trainingCurrentValue"
                                text="Current value"
                                value={forms.trainingCurrentValue || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="trainingDescription"
                                id="trainingDescription"
                                text="Description"
                                value={forms.trainingDescription || ""}
                                onChange={handleChangeSearch}
                            />
                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 ps-5 pb-5">
                            <button onClick={() => {
                                AddUpdate()
                            }} className="butAUAccount">{id ? "Update Company" : "Add Company"} </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center element flex-column w-25">
                        <div className="d-flex justify-content-center align-items-center mt-4 ">
                            <img className="w-75"
                                 src={User && User.companyCoverPic ? `data:image/jpeg;base64,${User.companyCoverPic}` : {bg}}
                                 alt=""/>
                            <img className="face"
                                 src={User && User.userProfilePic ? `data:image/jpeg;base64,${User.userProfilePic}` : face}
                                 alt=""/>
                        </div>
                        <span className="m-2">{User && User.firstName + " " + User && User.lastName}</span>
                        <span className="m-2">{User && User.email}</span>
                        <span className="m-2 mb-4">{User && User.jobTitleName}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectsTraining;
