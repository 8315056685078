import axios from "axios";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const client = axios.create({
    baseURL: window.BASE_URL,
});

const getHeader = () => {
    let token = localStorage.getItem("Token");
    let header = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
    };
    return header
}


export async function getToken(input) {
    let data = {username: input.username, password: input.password};
    data = JSON.stringify(data);
    try {
        const response = await client.post("/Users/Token", data, {
            headers: {
                "Content-Type": "application/json-patch+json",
            },
        });
        localStorage.setItem("Token", response.data.access_token);

        return response;
    } catch (error) {
        return (ErrorHandel(error))
    }
}


export async function getCompanyByUserName(id) {
    let header = getHeader();
    try {
        const response = await client.get("/Users/GetCompanyByUserName/" + id, {
            headers: header,
        });
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetLog(search) {
    let data = JSON.stringify(search)
    let header = getHeader();
    try {
        const response = await client.post("/MasterData/GetLog", data, {
            headers: header,
        });
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function getUserUsername(id) {
    let header = getHeader();

    try {
        const response = await client.post("/Users/GetUserByUserName/" + id, {
            headers: header,
        });
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function fetchIdentity(id) {
    let header = getHeader();

    try {
        let data = {id: id};

        const response = await client.post("/Identity/Fetch", (data),
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetProjectSearch(search) {
    let header = getHeader();
    let data = JSON.stringify(search)
    try {
        const response = await client.post("/Project/Filter", (data),
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function ProjectList(search) {
    let header = getHeader();
    let data = JSON.stringify(search)
    try {
        const response = await client.post("/Project/ProjectList", (data),
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetCompanySearch(search) {
    let header = getHeader();

    try {
        let data = JSON.stringify(search)
        const response = await client.post("/Company/Filter", (data),
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function CompanyList(search) {
    let header = getHeader();

    try {
        let data = JSON.stringify(search)
        const response = await client.post("/Company/CompanyList", (data),
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DeleteProject(id) {
    let header = getHeader();

    try {
        const response = await client.post("/Project/Delete/" + id, undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetUserUsername(userName) {
    let header = getHeader();

    try {
        const response = await client.post("/Users/GetUserByUserName/" + userName, undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function UpdateProjects(project, id) {
    let header = getHeader();
    let data = JSON.stringify(project);
    try {
        const response = await client.post("/Project/" + id, data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function AddProjects(project) {
    let header = getHeader();
    let data = JSON.stringify(project);
    try {
        const response = await client.post("/Project", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetMetricSearch(search) {
    let header = getHeader();
    let data = JSON.stringify(search);
    try {
        const response = await client.post("/Metric/Filter", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetCompanyUsers(id) {
    let header = getHeader();
    try {
        const response = await client.post("/Company/GetCompanyUsers/" + id, undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DeleteDataEntry(id) {
    let header = getHeader();
    try {
        const response = await client.post("/DataEntry/Delete/" + id, undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function UpdateDataEnters(DataEnter, id) {
    let header = getHeader();
    let data = JSON.stringify(DataEnter);
    try {
        const response = await client.post("/DataEntry/" + id, data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function AddDataEnters(DataEnter) {
    let header = getHeader();
    let data = JSON.stringify(DataEnter);
    try {
        const response = await client.post("/DataEntry", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetDataEntrySearch(search) {
    let header = getHeader();
    try {
        let data = JSON.stringify(search)
        const response = await client.post("/DataEntry/Filter", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function UpdateMetrics(metrics, id) {
    let header = getHeader();
    let data = JSON.stringify(metrics);
    try {
        const response = await client.post("/Metric/" + id, data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function AddMetrics(metric) {
    let data = JSON.stringify(metric);
    let header = getHeader();
    try {
        const response = await client.post("/Metric", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetMonitoringPeriod() {
    let header = getHeader();
    try {
        const response = await client.get("/MasterData/MonitoringPeriod/",
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DeleteMetric(id) {
    let header = getHeader();
    try {
        const response = await client.post("/Metric/Delete/" + id, undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function MetricList(search) {
    let header = getHeader();
    let data = JSON.stringify(search);
    try {
        const response = await client.post("/Metric/MetricList", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function UserList(search) {
    let header = getHeader();
    let data = JSON.stringify(search);
    try {
        const response = await client.post("/Users/UserList", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetMetricType() {
    let header = getHeader();
    try {
        const response = await client.get("/MasterData/MetricType",
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DeleteCompany(id) {
    let header = getHeader();

    try {
        const response = await client.post("/Company/Delete/" + id, undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DeleteUser(id) {
    let header = getHeader();

    try {
        const response = await client.post("/Users/Delete/" + id, undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}


export async function UpdateUsers(user, id) {
    let header = getHeader();

    try {
        let data = JSON.stringify(user)
        const response = await client.post("/Users/" + id, data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function AddUsers(user) {
    let header = getHeader();

    try {
        let data = JSON.stringify(user)
        const response = await client.post("/Users", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function ApplyIdentity(identity) {
    let header = getHeader();

    try {
        let data = JSON.stringify(identity)
        const response = await client.post("/Identity/Apply", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DashboardGetGvexMonetary() {
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetGvexMonetary", undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DashboardGetGVEXROIPortfolio() {
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetGVEXROIPortfolio", undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DashboardGetGvexMonthlyGainedValue() {
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetGvexMonthlyGainedValue", undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetGvexAccountPercentageGainedValue() {
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetGvexAccountPercentageGainedValue", undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DashboardGetProjectPercentageGainedValue(AccountId) {
    let data = JSON.stringify({
        accountId: AccountId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetProjectPercentageGainedValue", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetMetricPercentageGainedValue(ProjectId) {
    let data = JSON.stringify({
        projectId: ProjectId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetMetricPercentageGainedValue", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetMetricMonthlyGainedValue(ProjectId) {
    let data = JSON.stringify({
        projectId: ProjectId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetMetricMonthlyGainedValue", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function GetHardSoftSavingProject(ProjectId) {
    let data = JSON.stringify({
        projectId: ProjectId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Project/GetHardSoftSavingProject", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}export async function DashboardGetProjectTotalGainedValue(ProjectId) {
    let data = JSON.stringify({
        projectId: ProjectId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetProjectTotalGainedValue", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetProjectMetrics(ProjectId) {
    let data = JSON.stringify({
        projectId: ProjectId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetProjectMetrics", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetMetricMonetary(MetricId) {
    let data = JSON.stringify({
        metricId: MetricId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetMetricMonetary", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetMetricTotalGainedValue(MetricId) {
    let data = JSON.stringify({
        metricId: MetricId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetMetricTotalGainedValue", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DashboardGetProjectMonthlyGainedValue(AccountId) {
    let data = JSON.stringify({
        accountId: AccountId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetProjectMonthlyGainedValue", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetAccountMonetary(AccountId) {
    let data = JSON.stringify({
        accountId: AccountId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetAccountMonetary", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetAccountProjects(AccountId) {
    let data = JSON.stringify({
        accountId: AccountId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetAccountProjects", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function DashboardGetAccountTotalGainedValue(AccountId) {
    let data = JSON.stringify({
        accountId: AccountId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetAccountTotalGainedValue", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}
export async function GetHardSoftSavingAccount(AccountId) {
    let data = JSON.stringify({
        accountId: AccountId,
    })
    let header = getHeader();

    try {
        const response = await client.post("/Company/GetHardSoftSavingAccount", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function DashboardGetGvexAccounts() {
    let header = getHeader();

    try {
        const response = await client.post("/Dashboard/GetGvexAccounts", undefined,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetIndustry() {
    let header = getHeader();

    try {
        const response = await client.get("/MasterData/Industry",
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetJobTitle() {
    let header = getHeader();

    try {
        const response = await client.get("/MasterData/JobTitle",
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function UpdateCompanies(company, id) {
    let header = getHeader();

    try {
        let data = JSON.stringify(company);
        const response = await client.post("/Company/" + id, data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function AddCompanies(company) {
    let header = getHeader();

    try {
        let data = JSON.stringify(company);
        const response = await client.post("/Company", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

export async function GetUserSearch(company) {
    let header = getHeader();

    try {
        let data = JSON.stringify(company);
        const response = await client.post("/Users/Filter", data,
            {headers: header});
        return (SuccessHandel(response))
    } catch (error) {
        return (ErrorHandel(error))
    }
}

const ErrorHandel = (error) => {
    console.log(error.response);
    if (error.response === undefined) {
        toast.error("undefined Error")
        // Unauthorized();
    } else {
        if (error.response.status === 400) {
            toast.error(error.response.data.message)
        } else {
            if (error.response.status === 403) {
                toast.error("Forbidden")
                Forbidden()
            } else {
                if (error.response.status === 401) {
                    toast.error("Unauthorized")
                    // Unauthorized();
                } else {
                    toast.error("Server Error")
                }
            }

        }
    }
}
const SuccessHandel = (data) => {
    let copyData = data;
    if (!data.data.isSuccess) {
        copyData.status = 400
        toast.error(data.data.message)
    }
    return copyData
}
const Unauthorized = () => {
    localStorage.removeItem("Identity");
    localStorage.removeItem("Username");
    localStorage.removeItem("CompanyUserName");
    localStorage.removeItem("Token");
    const navigate = useNavigate();
    navigate("/login");
}
const Forbidden = () => {

    const navigate = useNavigate();
    navigate("/panel");
}
