import "./Select.css";
import {useRef} from "react";


function Select({type, name, id, text, onChange, value, note, option}) {
    const pInput = useRef();
    return (
        <div className="form-item">
            <p ref={pInput} className={value ? "formLabel formTop" : "formLabel"}>{text}</p>
            <select
                value={value}
                onChange={(e) => {
                    onChange(e)
                }}
                onBlur={(e) => {
                    e.target.value === "" && pInput.current.classList.remove("formTop")
                }} onFocus={() => {
                pInput.current.classList.add("formTop")
            }} type={type} name={name} id={id} className="form-style" autoComplete="off">
                <option></option>
                {option && option.map((e, key) =>
                    <option key={key} value={e.value}>{e.title}</option>
                )}
            </select>
            <p className="note p-1 ps-3">{note}</p>
        </div>
    )
}

export default Select;
