import "./Activities.css";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {GetLog, UserList} from "../../Api/Services";
import Select from "../InputCus/Select/Select";
import InputCus from "../InputCus/Text/InputCus";
import {Pagination} from "react-bootstrap";

const ModelActivities = {
    userId: "",
    requestDateFrom: null,
    requestDateTo: null,
    serviceId: "",
    requestTypeId: "",
    ip: "",
}
function Activities() {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [dataDropDownUser, stataDropDownUser] = useState([])
    const [Search, setSearch] = useState({ ...ModelActivities });
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(1);
    const [Pages, setPages] = useState([]);

    const navigate = useNavigate();

    const start = async () => {
        try {
            const GetLogData = await GetLog(
                {
                    gridParameters: {
                        pageNumber: Page,
                        pageSize: 10
                    }
                }
            )
            if (GetLogData.status && GetLogData.status === 200) {
                setData(GetLogData.data.data.dataList)
                let newPages = [];
                let size = Math.ceil(GetLogData.data.data.resultCount / 10);
                for (let i = 1; i < size + 1; i++) {
                    newPages.push(i)
                }
                setPages(newPages)
                setPageSize(size)

                const UserListData = await UserList({})
                if (UserListData.status && UserListData.status === 200) {
                    let a = [];
                    UserListData.data.data.forEach((e) => {
                        a.push({value: e.description, title: e.description})
                    })
                    stataDropDownUser(a)
                }

            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    const Clear = async () => {
        setPage(1)
        setSearch({})
        setLoading(true)
        try {
            const GetLogData = await GetLog(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 10
                    }
                }
            )
            if (GetLogData.status && GetLogData.status === 200) {
                setData(GetLogData.data.data.dataList);
                setSearch({ ...ModelActivities })
                let newPages = [];
                let size = Math.ceil(GetLogData.data.data.resultCount / 10);
                for (let i = 1; i < size + 1; i++) {
                    newPages.push(i)
                }
                setPages(newPages)
                setPageSize(size)
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const Searched = async () => {
        try {
            setPage(1)
            setLoading(true)
            let searching = {...Search}
            let Paging = {
                gridParameters: {
                    pageNumber: 1,
                    pageSize: 10
                }
            }
            const GetLogData = await GetLog({...searching, ...Paging})
            if (GetLogData.status && GetLogData.status === 200) {
                setData(GetLogData.data.data.dataList);
                let newPages = [];
                let size = Math.ceil(GetLogData.data.data.resultCount / 10);
                for (let i = 1; i < size + 1; i++) {
                    newPages.push(i)
                }
                setPages(newPages)
                setPageSize(size)
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const handleChangeSearch = (e) => {
        const newSearch = {...Search};
        newSearch[e.target.id] = e.target.value;
        setSearch(newSearch);
    }


    const ChangePage = async (e, des) => {
        try {
            let ThisPage;
            setLoading(true);
            if (des === "Next") {
                setPage(Page + 1)
                ThisPage = Page + 1
            } else {
                if (des === "Back") {
                    setPage(Page - 1)
                    ThisPage = Page - 1
                } else {
                    ThisPage = des
                    setPage(des)
                }
            }
            let searching = {...Search}
            let Paging = {
                gridParameters: {
                    pageNumber: ThisPage,
                    pageSize: 10
                }
            }
            setLoading(true)
            const GetLogData = await GetLog({...searching, ...Paging})
            if (GetLogData.status && GetLogData.status === 200) {
                setData(GetLogData.data.data.dataList);
                let newPages = [];
                let size = Math.ceil(GetLogData.data.data.resultCount / 10);
                for (let i = 1; i < size + 1; i++) {
                    newPages.push(i)
                }
                setPages(newPages)
                setPageSize(size)
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }


    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column element p-5">
                    <div className="d-flex justify-content-startalign-items-center w-100">
                        <span className="AccountsTitle">List Of Data Entry</span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <Select
                            type="text"
                            name="userId"
                            id="userId"
                            text="User Id"
                            value={Search.userId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownUser}
                        />
                        <InputCus
                            type="date"
                            name="requestDateFrom"
                            id="requestDateFrom"
                            text="request Date From"
                            value={Search.requestDateFrom || ""}
                            onChange={handleChangeSearch}
                        />
                        <InputCus
                            type="date"
                            name="requestDateTo"
                            id="requestDateTo"
                            text="request Date To"
                            value={Search.requestDateTo || ""}
                            onChange={handleChangeSearch}
                        />
                        <Select
                            type="text"
                            name="serviceId"
                            id="serviceId"
                            text="Service Id"
                            value={Search.serviceId || ""}
                            onChange={handleChangeSearch}
                            option={[
                                {value: "Account", title: "Account"},
                                {value: "Project", title: "Project"},
                                {value: "Metric", title: "Metric"},
                                {value: "DataEntry", title: "DataEntry"},
                                {value: "User", title: "User"}
                            ]}
                        />
                        <Select
                            type="text"
                            name="requestTypeId"
                            id="requestTypeId"
                            text="Request Type Id"
                            value={Search.requestTypeId || ""}
                            onChange={handleChangeSearch}
                            option={[
                                {value: "Create", title: "Create"},
                                {value: "Update", title: "Update"},
                                {value: "Delete", title: "Delete"},
                            ]}
                        />
                        <InputCus
                            type="text"
                            name="ip"
                            id="ip"
                            text="Ip"
                            value={Search.ip || ""}
                            onChange={handleChangeSearch}
                        />
                    </div>
                    <div className="d-flex justify-content-start align-items-center w-100">
                        <button onClick={() => {
                            Searched()
                        }} className="m-3 btnSearch">Search
                        </button>
                        <button onClick={() => {
                            Clear()
                        }} className="btnCancel">Clear
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 p-2 tableC">
                        <table className="table">
                            <thead>
                            <tr className="trTable">
                                <th>userId</th>
                                <th>requestDate</th>
                                <th>serviceId</th>
                                <th>requestTypeId</th>
                                <th>ip</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item, key) =>
                                <tr key={key}>
                                    <td>
                                        {item.userId}
                                    </td>
                                    <td>
                                        {item.requestDate}
                                    </td>
                                    <td>
                                        {item.serviceId}
                                    </td>
                                    <td>
                                        {item.requestTypeId}
                                    </td>

                                    <td>
                                        {item.ip}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <Pagination className="d-flex justify-content-center">
                            {Page === 1 ? <Pagination.Prev disabled onClick={(e) => {
                                ChangePage(e, "Back")
                            }}/> : <Pagination.Prev onClick={(e) => {
                                ChangePage(e, "Back")
                            }}/>}

                            {Pages.map(item =>
                                <Pagination.Item active={item === Page} onClick={(e) => {
                                    ChangePage(e, item)
                                }}>{item}</Pagination.Item>)}
                            {PageSize > 10 ? <Pagination.Ellipsis/> : null}
                            {Page === PageSize ? <Pagination.Next disabled onClick={(e) => {
                                ChangePage(e, "Next")
                            }}/> : <Pagination.Next onClick={(e) => {
                                ChangePage(e, "Next")
                            }}/>}
                        </Pagination>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activities;
