import "./Projects.css";
import InputCus from "../InputCus/Text/InputCus";
import { useEffect, useState } from "react";
import Select from "../InputCus/Select/Select";
import { CompanyList, DeleteProject, GetProjectSearch, ProjectList, UpdateProjects, } from "../../Api/Services";
import imgDelete from "../../assets/Icon/Delete, Disabled.png"
import imgDoc from "../../assets/Icon/Document, Content.png"
import { confirmAlert } from 'react-confirm-alert';
import imgPlus from "../../assets/Icon/pluse.png"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdaptorDTO from "../../Common/AdaptorDTO";


const ModelProject = {
    "projectName": "",
    "startDate": "",
    "endDate": "",
    "projectLead": "",
    "projectSponsor": "",
    "reactionInitialValue": 0,
    "reactionCurrentValue": 0,
    "cost": 0,
    "reactionDescription": "",
    "trainingInitialValue": 0,
    "trainingCurrentValue": 0,
    "trainingDescription": "",
    "applicationInitialValue": 0,
    "applicationCurrentValue": 0,
    "applicationDescription": "",
    "companyId": 0,
}


function Projects() {
    const [Search, setSearch] = useState({})
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true);
    const [dataDropDownAccount, setDropDownAccount] = useState([])
    const [dataDropDownProject, setDataDropDownProject] = useState([])
    const [FormIdentity, setFormIdentity] = useState();
    const [CostValue, setCostValue] = useState();
    const [id, setId] = useState();
    const [ShowModal, setShowModal] = useState(false);
    const [forms, setForm] = useState({ ...ModelProject })
    const navigate = useNavigate();




    const handelModalPage = async (e) => {
        try {
            setLoading(true)
            setId(e)
            const GetProjectSearchData = await GetProjectSearch({ id: e })
            if (GetProjectSearchData.status && GetProjectSearchData.status === 200) {
                setForm(AdaptorDTO({ ...ModelProject }, GetProjectSearchData.data.data.dataList[0]));
                setCostValue(GetProjectSearchData.data.data.dataList[0].cost)
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
            setShowModal(true)
        }
    }
    const SendCost = async () => {
        setLoading(true)
        setShowModal(false)
        try {
            let copy = { ...forms };
            copy.Cost = CostValue;
            const UpdateProjectsData = await UpdateProjects(copy, id)
            if (UpdateProjectsData.status && UpdateProjectsData.status === 200) {
                toast.success('Your information has been update!');
                navigate("/Panel/Projects");
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
            setShowModal(false)
            setCostValue();
            setId();
            setForm()
        }
    }



    const start = async () => {
        try {
            setFormIdentity(JSON.parse(localStorage.getItem('Identity')));
            const GetProjectSearchData = await GetProjectSearch(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetProjectSearchData.status && GetProjectSearchData.status === 200) {
                setData(GetProjectSearchData.data.data.dataList);
                const ProjectListData = await ProjectList({})
                if (ProjectListData.status && ProjectListData.status === 200) {
                    let a = [];
                    ProjectListData.data.data.forEach((e) => {
                        a.push({ value: e.id, title: e.description })
                    })
                    setDataDropDownProject(a)
                    const CompanyListData = await CompanyList({})
                    if (CompanyListData.status && CompanyListData.status === 200) {
                        let c = [];
                        CompanyListData.data.data.forEach((e) => {
                            c.push({ value: e.id, title: e.description })
                        })
                        setDropDownAccount(c)
                    }
                }
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        start();
    }, []
    )

    const Clear = async () => {
        setSearch({})
        setLoading(true)
        try {
            const GetProjectSearchData = await GetProjectSearch(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetProjectSearchData.status && GetProjectSearchData.status === 200) {
                setData(GetProjectSearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const Searched = async () => {
        try {
            setLoading(true)
            let searching = { ...Search }
            let Paging = {
                gridParameters: {
                    pageNumber: 1,
                    pageSize: 1000
                }
            }
            const GetProjectSearchData = await GetProjectSearch({ ...searching, ...Paging })
            if (GetProjectSearchData.status && GetProjectSearchData.status === 200) {
                setData(GetProjectSearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const confirmDelete = (e) => {
        confirmAlert({
            title: 'Delete Company',
            message: 'Are You Sure To Do Remove Company ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteItem(e)
                },
                {
                    label: 'No',
                }
            ]
        });
    };
    const DeleteItem = async (e) => {

        try {
            setLoading(true)
            const DeleteProjectData = await DeleteProject(e)
            if (DeleteProjectData.status && DeleteProjectData.status === 200) {
                toast.success('Your information has been delete!');
                await start();
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }

    }
    const Add = () => {
        navigate("/Panel/Projects/Add");
    }
    const Edite = (id) => {
        navigate("/Panel/Projects/Edite/" + id);
    }
    const Reaction = (id) => {
        navigate("/Panel/Projects/Reaction/" + id);
    }
    const Training = (id) => {
        navigate("/Panel/Projects/Training/" + id);
    }
    const application = (id) => {
        navigate("/Panel/Projects/Application/" + id);
    }

    const handleChangeSearch = (e) => {
        const newSearch = { ...Search };
        newSearch[e.target.id] = e.target.value;
        setSearch(newSearch);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column element p-5">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <span className="AccountsTitle">Projects</span>
                        {FormIdentity && FormIdentity.Project.Create && <img onClick={() => {
                            Add()
                        }} src={imgPlus} alt="" />}
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <Select
                            type="text"
                            name="companyId"
                            id="companyId"
                            text="Account"
                            value={Search.companyId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownAccount}
                        />
                        <Select
                            type="text"
                            name="projectId"
                            id="projectId"
                            text="Project"
                            value={Search.projectId || ""}
                            onChange={handleChangeSearch}
                            option={dataDropDownProject}
                        />
                        <InputCus
                            type="date"
                            name="startDate"
                            id="startDate"
                            text="Start Date"
                            value={Search.startDate || ""}
                            onChange={handleChangeSearch}
                        />
                        <InputCus
                            type="text"
                            name="projectLead"
                            id="projectLead"
                            text="Project Lead"
                            value={Search.projectLead || ""}
                            onChange={handleChangeSearch}

                        />
                        <InputCus
                            type="text"
                            name="projectSponsor"
                            id="projectSponsor"
                            text="Project Sponsor"
                            value={Search.projectSponsor || ""}
                            onChange={handleChangeSearch}

                        />

                    </div>
                    <div className="d-flex justify-content-start align-items-center w-100">
                        <button onClick={() => {
                            Searched()
                        }} className="m-3 btnSearch">Search
                        </button>
                        <button onClick={() => {
                            Clear()
                        }} className="btnCancel">Clear
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 p-2 tableC">
                        <table className="table">
                            <thead>
                                <tr className="trTable">
                                    <th>Account</th>
                                    <th>Project</th>
                                    <th>Start Date</th>
                                    <th>Project Lead</th>
                                    <th>Project Sponsor</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, key) =>
                                    <tr key={key}>
                                        <td>
                                            {item.companyName}
                                        </td>
                                        <td>
                                            {item.projectName}
                                        </td>
                                        <td>
                                            {item.startDate.toString().substring(0, 10)}
                                        </td>
                                        <td>
                                            {item.projectLead}
                                        </td>
                                        <td>
                                            {item.projectSponsor}
                                        </td>

                                        <td className="td-actions">

                                            {FormIdentity && FormIdentity.Project.Delete &&
                                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                    title="Remove Project ...">
                                                    <img className="me-2" src={imgDelete} alt="Remove Project ..."
                                                        onClick={() => {
                                                            confirmDelete(item.id)
                                                        }} />
                                                </span>}
                                            {FormIdentity && FormIdentity.Project.Update &&
                                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                    title="Edite Project ...">

                                                    <img className="me-2" src={imgDoc} alt=" Edite Project ..." onClick={() => {
                                                        Edite(item.id)
                                                    }} />
                                                </span>}
                                            {FormIdentity && FormIdentity.Project.Update &&
                                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                    title="Reaction Project ...">

                                                    <img className="me-2" src={imgDoc} alt=" Reaction Project ..."
                                                        onClick={() => {
                                                            Reaction(item.id)
                                                        }} />
                                                </span>}
                                            {FormIdentity && FormIdentity.Project.Update &&
                                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                    title="Training Project ...">

                                                    <img className="me-2" src={imgDoc} alt=" Training Project ..."
                                                        onClick={() => {
                                                            Training(item.id)
                                                        }} />
                                                </span>}
                                            {FormIdentity && FormIdentity.Project.Update &&
                                                <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                    title="application Project ...">

                                                    <img className="me-2" src={imgDoc} alt=" application Project ..."
                                                        onClick={() => {
                                                            application(item.id)
                                                        }} />
                                                    {FormIdentity && FormIdentity.Project.Update &&
                                                        <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                            title="application Cost ...">

                                                            <img className="me-2" src={imgDoc} alt=" application Cost ..."
                                                                onClick={() => {
                                                                    handelModalPage(item.id)
                                                                }} />
                                                        </span>}
                                                </span>}

                                        </td>

                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={ShowModal ? "modal" : "modal-close"}>
                <div className="modal-content d-flex">
                    <span onClick={() => {
                        setLoading(false);
                        setShowModal(false);
                        setCostValue();
                        setId();
                        setForm();
                    }
                    } className="close">&times;</span>
                    <div className="d-flex justify-content-start align-items-center flex-column p-2">
                        <span className="AccountsTitle ms-2">{forms && forms.projectName}</span>
                        <InputCus

                            type="number"
                            name="Cost"
                            id="Cost"
                            text="application Cost"
                            value={CostValue || ""}
                            onChange={(e) => { setCostValue(e.target.value) }}
                        />
                        <button onClick={() => { SendCost() }} className="butAUAccount mt-2">
                            Update Cost
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Projects;

