import "./InputCus.css";
import {useRef} from "react";


function InputCus({type, name, id, text, onChange, note, value, width,disabled=false}) {
    const pInput = useRef();
    return (
        <div className="form-item" style={{width: width}}>
            <p ref={pInput} className={value ||type=="date"? "formLabel formTop" : "formLabel"}>{text}</p>
            {/*<p ref={pInput} className="formLabel">{text}</p>*/}
            <input value={value}
                   disabled={disabled}
                   onChange={(e) => {
                       onChange(e)
                   }}
                     onBlur={(e) => {
                       e.target.value === "" && type!="date" && pInput.current.classList.remove("formTop")
                   }} onFocus={() => {
                pInput.current.classList.add("formTop")
            }} type={type} name={name} id={id} className="form-style" autoComplete="off"/>
            <p className="note p-1 ps-3">{note}</p>
        </div>
    )
}

export default InputCus;
