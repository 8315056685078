import "./DataEntryDet.css";
import {GetDataEntrySearch} from "../../../../Api/Services";
import React, {useEffect, useState} from "react";

function DataEntryDet({metricId}) {
    const [DataEntry, setDataEntry] = useState([])
    const [isLoading, setLoading] = useState(true);


    const start = async () => {
        try {
            const GetDataEntrySearchData = await GetDataEntrySearch({metricId: metricId})
            if (GetDataEntrySearchData.status && GetDataEntrySearchData.status === 200) {
                setDataEntry(GetDataEntrySearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column element p-5">
                    <div className="d-flex justify-content-startalign-items-center w-100">
                        <span className="AccountsTitle">Data Entry</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 p-2 tableC">
                        <table className="table">
                            <thead>
                            <tr className="trTable">
                                <th>Metric</th>
                                <th>start Date</th>
                                <th>Metric absolute value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {DataEntry.map((item, key) =>
                                <tr key={key}>
                                    <td>
                                        {item.metricName}
                                    </td>
                                    <td>
                                        {item.startDate}
                                    </td>
                                    <td>
                                        {item.metricAbsoluteValue}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataEntryDet;
