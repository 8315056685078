import ProjectList from "./Component/ProjectList";
import DetCompany from "./Component/DetCompany";
import AreaCompany from "./Component/AreaCompany";
import BarCompany from "./Component/BarCompany";
import PieCompany from "./Component/PieCompany";
import './DashboardCompany.css'
import {DashboardGetAccountMonetary, GetCompanySearch, GetHardSoftSavingAccount} from "../../../Api/Services";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";


function DashboardCompany() {
    const [CompanyName, setCompanyName] = useState();
    const [CompanyLogo, setCompanyLogo] = useState();
    const [isLoading, setLoading] = useState(true);

    let {id} = useParams();


    const ChartColor = [
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE"
    ]

    const start = async () => {
        try {
            const GetCompanySearchData = await GetCompanySearch({id: id})
            if (GetCompanySearchData.status && GetCompanySearchData.status === 200) {
                setCompanyName(GetCompanySearchData.data.data.dataList[0].name)
                setCompanyLogo(GetCompanySearchData.data.data.dataList[0].logo)
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )
    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column">
                    <div className="w-100" style={{"padding-bottom": "30px", "border-bottom": "2px solid #e3e3e3"}}>
                        <DetCompany companyId={id} CompanyName={CompanyName} CompanyLogo={CompanyLogo}/>
                    </div>
                    <div className="w-100 mt-4">
                        <ProjectList companyId={id}/>
                    </div>
                    <div className="w-100 mt-4">
                        <AreaCompany CompanyName={CompanyName} companyId={id}/>
                    </div>
                    <div className="w-100 mt-4">
                        <BarCompany companyId={id} ChartColor={ChartColor}/>
                    </div>
                    <div className="w-100 mt-4">
                        <PieCompany companyId={id} ChartColor={ChartColor}/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DashboardCompany;
