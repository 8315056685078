export default function AdaptorDTO(obj, data) {
    let result = {};
    for (let keyData in data) {
        if ({}.hasOwnProperty.call(data, keyData)) {
            for (let keyObj in obj) {
                if ({}.hasOwnProperty.call(obj, keyObj)) {
                    if (keyObj == keyData)
                        result[keyData] = data[keyData]
                }
            }
        }
    }
    return result;
}