import {Route, Routes, Navigate} from "react-router-dom";
import "./App.css";
import Panel from "./Component/Panel/Panel";
import Login from "./Component/Login/Login";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ForgetPassword from "./Component/ForgetPassword/ForgetPassword";
import Accounts from "./Component/Accounts/Accounts";
import Users from "./Component/Users/Users";
import Projects from "./Component/Projects/Projects";
import Metrics from "./Component/Metrics/Metrics";
import DataEntry from "./Component/DataEntry/DataEntry";
import Approval from "./Component/Approval/Approval";
import Activities from "./Component/Activities/Activities";
import "./assets/Style/Loading.css"
import "./assets/Style/Tabel.css"
import 'react-confirm-alert/src/react-confirm-alert.css';
import AccountsAE from "./Component/Accounts/AccountsAE";
import UsersAE from "./Component/Users/UsersAE";
import ProjectsAE from "./Component/Projects/ProjectsAE";
import ProjectsReaction from "./Component/Projects/ProjectsReaction";
import ProjectsTraining from "./Component/Projects/ProjectsTraining";
import ProjectsApplication from "./Component/Projects/ProjectsApplication";
import MetricsAE from "./Component/Metrics/MetricsAE";
import DataEntryAE from "./Component/DataEntry/DataEntryAE";
import ApprovalDetails from "./Component/Approval/ApprovalDetails";
import Home from "./Component/Home/Home";
import DashboardSuperAdmin from "./Component/DashboardPanel/SuperAdmin/DashboardSuperAdmin";
import DashboardCompany from "./Component/DashboardPanel/Company/DashboardCompany";
import DashboardProject from "./Component/DashboardPanel/Project/DashboardProject";
import DashboardMetric from "./Component/DashboardPanel/Metric/DashboardMetric";


const NavPanel = ({element}) => {
    let Token = localStorage.getItem("Token");
    return Token !== null ? <Navigate replace to="/Panel"/> : element;
};
const NavLogin = ({element}) => {
    let Token = localStorage.getItem("Token");
    return Token !== null ? element : <Navigate replace to="/login"/>;
};
const NavDashboard = ({element}) => {
    let Token = localStorage.getItem("Token");
    if (Token) {
        let Identity = JSON.parse(localStorage.getItem('Identity'));
        if (Identity.Dashboard.DashboardGetMetricMonetary) {
            let user = localStorage.getItem('Username');
            if (user === "superAdmin") {
                return <Navigate replace to="/Panel/Dashboard/SuperAdmin"/>
            }
            return <Navigate replace to={`/Panel/Dashboard/Company/${localStorage.getItem("CompanyUserName")}`}/>
        }
        return element;
    } else {
        return <Navigate replace to="/login"/>
    }
};
const NavDashboardSuperAdmin = ({element1, element2}) => {
    let Token = localStorage.getItem("Token");
    if (Token) {
        let Identity = JSON.parse(localStorage.getItem('Identity'));
        if (Identity.Dashboard.DashboardGetMetricMonetary) {
            let user = localStorage.getItem('Username');
            if (user === "superAdmin") {
                return element1;
            }
            return <Navigate replace to={`/Panel/Dashboard/Company/${localStorage.getItem("CompanyUserName")}`}/>
        }
        return element2;
    } else {
        return <Navigate replace to="/login"/>
    }
};
const NavyDashboardDetail = ({element1, element2}) => {
    let Token = localStorage.getItem("Token");
    if (Token) {
        let Identity = JSON.parse(localStorage.getItem('Identity'));
        if (Identity.Dashboard.DashboardGetMetricMonetary) {
            console.log(element1)
            console.log("sss")
            return element1;
        }
        return element2;
    } else {
        return <Navigate replace to="/login"/>
    }
};


function App() {
    return (
        <>
            <ToastContainer/>
            <Routes>
                <Route path="login" element={<NavPanel element={<Login/>}/>}/>
                <Route path="forgetpassword" element={<NavPanel element={<ForgetPassword/>}/>}/>
                <Route path="/" element={<NavPanel element={<Navigate replace to="/login"/>}/>}/>
                <Route path="*" element={<NavPanel element={<Navigate replace to="/login"/>}/>}/>

                <Route path="Panel" element={<NavLogin element={<Navigate replace to="/Panel/Dashboard"/>}/>}/>

                <Route path="/Panel/Dashboard" element={<NavDashboard element={<Panel element={<Home/>}/>}/>}/>

                <Route path="/Panel/Dashboard/SuperAdmin"
                       element={<NavDashboardSuperAdmin element1={<Panel element={<DashboardSuperAdmin/>}/>}
                                                        element2={<Panel element={<Home/>}/>}/>}/>

                <Route path="/Panel/Dashboard/Company/:id"
                       element={<NavyDashboardDetail element1={<Panel element={<DashboardCompany/>}/>}
                                                     element2={<Panel element={<Home/>}/>}/>}/>

                <Route path="/Panel/Dashboard/Project/:id"
                       element={<NavyDashboardDetail element1={<Panel element={<DashboardProject/>}/>}
                                                     element2={<Panel element={<Home/>}/>}/>}/>

                <Route path="/Panel/Dashboard/Metric/:id"
                       element={<NavyDashboardDetail element1={<Panel element={<DashboardMetric/>}/>}
                                                     element2={<Panel element={<Home/>}/>}/>}/>


                <Route path="/Panel/Accounts" element={<NavLogin element={<Panel element={<Accounts/>}/>}/>}/>
                <Route path="/Panel/Accounts/Add" element={<NavLogin element={<Panel element={<AccountsAE/>}/>}/>}/>
                <Route path="/Panel/Accounts/Edite/:id"
                       element={<NavLogin element={<Panel element={<AccountsAE/>}/>}/>}/>


                <Route path="/Panel/Users" element={<NavLogin element={<Panel element={<Users/>}/>}/>}/>
                <Route path="/Panel/Users/Add" element={<NavLogin element={<Panel element={<UsersAE/>}/>}/>}/>
                <Route path="/Panel/Users/Edite/:id" element={<NavLogin element={<Panel element={<UsersAE/>}/>}/>}/>

                <Route path="/Panel/Projects" element={<NavLogin element={<Panel element={<Projects/>}/>}/>}/>
                <Route path="/Panel/Projects/Add" element={<NavLogin element={<Panel element={<ProjectsAE/>}/>}/>}/>
                <Route path="/Panel/Projects/Edite/:id"
                       element={<NavLogin element={<Panel element={<ProjectsAE/>}/>}/>}/>
                <Route path="/Panel/Projects/Reaction/:id"
                       element={<NavLogin element={<Panel element={<ProjectsReaction/>}/>}/>}/>
                <Route path="/Panel/Projects/Training/:id"
                       element={<NavLogin element={<Panel element={<ProjectsTraining/>}/>}/>}/>
                <Route path="/Panel/Projects/Application/:id"
                       element={<NavLogin element={<Panel element={<ProjectsApplication/>}/>}/>}/>

                <Route path="/Panel/Metrics" element={<NavLogin element={<Panel element={<Metrics/>}/>}/>}/>
                <Route path="/Panel/Metrics/Add" element={<NavLogin element={<Panel element={<MetricsAE/>}/>}/>}/>
                <Route path="/Panel/Metrics/Edite/:id" element={<NavLogin element={<Panel element={<MetricsAE/>}/>}/>}/>

                <Route path="/Panel/DataEntry" element={<NavLogin element={<Panel element={<DataEntry/>}/>}/>}/>
                <Route path="/Panel/DataEntry/Add" element={<NavLogin element={<Panel element={<DataEntryAE/>}/>}/>}/>
                <Route path="/Panel/DataEntry/Edite/:id"
                       element={<NavLogin element={<Panel element={<DataEntryAE/>}/>}/>}/>

                <Route path="/Panel/Approval" element={<NavLogin element={<Panel element={<Approval/>}/>}/>}/>
                <Route path="/Panel/Approval/ApprovalDetails/:id"
                       element={<NavLogin element={<Panel element={<ApprovalDetails/>}/>}/>}/>

                <Route path="/Panel/Activities" element={<NavLogin element={<Panel element={<Activities/>}/>}/>}/>

            </Routes>
        </>

    )
}

export default App;
