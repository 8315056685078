import React, { useEffect, useState } from "react";
import './AreaMetric.css'
import {DashboardGetAccountTotalGainedValue, DashboardGetMetricTotalGainedValue} from "../../../../Api/Services";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

function AreaMetric(props) {
    const [Data, setData] = useState();
    const [isLoading, setLoading] = useState(true);

    const start = async () => {
        try {
            const DashboardGetMetricTotalGainedValueData = await DashboardGetMetricTotalGainedValue(props.metricId)
            if (DashboardGetMetricTotalGainedValueData.status && DashboardGetMetricTotalGainedValueData.status === 200) {
                let response = DashboardGetMetricTotalGainedValueData.data.data;
                let data = [];
                response.forEach(element => {
                    data.push({ Date: element.dataYear + "/" + element.dataMonth, Value: (element.sumSavingValue/1000) });
                });
                setData(data);
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        start();
    }, []
    )

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading boxShadow"}>
                <div className="d-flex justify-content-center align-items-center flex-column w-100 element">
                    <div className="d-flex justify-content-center align-items-center w-100 m-5">
                        <span className="titleChart">{props.metricName + " Total Gained Value (K)"}</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100">
                        <AreaChart width={1000} height={250} data={Data}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#FFFFFF" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#3CC5E3" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="Date" fontSize={"10px"}/>
                            <YAxis fontSize={"11px"}/>
                            <CartesianGrid strokeDasharray="0" stroke="#E5E5EF" vertical={false} />
                            <Tooltip />
                            <Area type="monotone" dataKey="Value" stroke="#3CC5E3" fillOpacity={1} fill="url(#colorValue)" />

                        </AreaChart>
                    </div>
                   
                </div>
            </div>
        </div>


    );
}

export default AreaMetric;
