import React, {useEffect, useState} from "react";
import {
    DashboardGetMetricPercentageGainedValue,
    DashboardGetProjectPercentageGainedValue
} from "../../../../Api/Services";
import {PieChart, Pie, Sector, Cell} from 'recharts';


const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{` ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};


function PieProject({ChartColor, projectId}) {
    const [Data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [ActiveIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };
    const start = async () => {
        try {
            const DashboardGetMetricPercentageGainedValueData = await DashboardGetMetricPercentageGainedValue(projectId)
            if (DashboardGetMetricPercentageGainedValueData.status && DashboardGetMetricPercentageGainedValueData.status === 200) {
                let MonthlyGained = DashboardGetMetricPercentageGainedValueData.data.data;
                console.log(MonthlyGained)
                let data = []
                MonthlyGained.forEach(e => {
                    data.push({name: e.projectName, value: e.metricPercent})
                })
                console.log(data);
                setData(data)
            }

        } catch
            (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading boxShadow"}>
                <div className="d-flex justify-content-center align-items-center flex-column w-100 element">
                    <div className="d-flex justify-content-center align-items-center w-100 m-5">
                        <span className="titleChart">Project Percentage Gained Value</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100">

                        <PieChart width={1000} height={400}>
                            <Pie
                                activeIndex={ActiveIndex}
                                activeShape={renderActiveShape}
                                data={Data}
                                cx="50%"
                                cy="50%"
                                innerRadius={100}
                                outerRadius={170}
                                fill="#8884d8"
                                dataKey="value"
                                onMouseEnter={onPieEnter}

                            >
                                {Data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={ChartColor[index % ChartColor.length]}/>
                                ))}
                            </Pie>
                        </PieChart>
                    </div>
                </div>
            </div>
        </div>


    );

}

export default PieProject;
