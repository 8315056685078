import GvexAccounts from "./Component/AccountList";
import DetSuperAdmin from "./Component/DetSuperAdmin";
import AreaSuperAdmin from "./Component/AreaSuperAdmin";
import BarSuperAdmin from "./Component/BarSuperAdmin";
import PieSuperAdmin from "./Component/PieSuperAdmin";
import './DashboardSuperAdmin.css'

function DashboardSuperAdmin() {
    const ChartColor = [
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE",
        "#3CC5E3",
        "#85E33C",
        "#EAC76D",
        "#FF6E5A",
        "#DAD7FE"
    ]


    return (
        <div>
            <div className="stateLoading">
                <div className="w-100 d-flex justify-content-start align-items-center flex-column">
                    <div className="w-100" style={{ "padding-bottom": "30px", "border-bottom": "2px solid #e3e3e3" }}>
                        <DetSuperAdmin />
                    </div>
                    <div className="w-100 mt-4">
                        <GvexAccounts />
                    </div>
                    <div className="w-100 mt-4">
                        <AreaSuperAdmin />
                    </div>
                    <div className="w-100 mt-4">
                        <BarSuperAdmin ChartColor={ChartColor} />
                    </div>
                    <div className="w-100 mt-4">
                        <PieSuperAdmin ChartColor={ChartColor} />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DashboardSuperAdmin;
