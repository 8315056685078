import React, {useEffect, useState} from "react";
import {numberWithCommas} from "../../../../Common/Tools";
import {DashboardGetGvexAccounts} from "../../../../Api/Services";
import './AccountList.css'
import {useNavigate} from "react-router-dom";

function AccountList() {
    const [Accounts, setAccounts] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();


    const start = async () => {
        try {
            const DashboardGetGvexAccountsData = await DashboardGetGvexAccounts()
            if (DashboardGetGvexAccountsData.status && DashboardGetGvexAccountsData.status === 200) {
                setAccounts(DashboardGetGvexAccountsData.data.data);
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )
    return (


        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="d-flex justify-content-start align-items-center flex-wrap">
                    {Accounts.map(iteam => (
                        <div onClick={() => {
                            navigate("/Panel/Dashboard/Company/" + iteam.accountId);
                        }} className="element d-flex flex-column m-2 p-2 GvexAccountsRadio boxShadow">
                            <div className="d-flex justify-content-between align-items-center">
                                <img
                                    width="50px"
                                    height="auto"
                                    src={`data:image/jpeg;base64,${iteam.accountLogo}`}
                                />
                                <div className="d-flex justify-content-center align-items-end flex-column">
                                    <p className="card-category-text">
                                        {iteam.accountName}
                                    </p>
                                    <p className="card-category-value">
                                        $ {numberWithCommas(iteam.sumSavingValue)}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-start flex-column">
                                <div>
                                        <span className="hardSoft me-2">
                                              Hard Savings
                                        </span>
                                    <span className="hardValue">
                                          ${numberWithCommas(iteam.sumHardSavingMetrics)}
                                      </span>

                                </div>
                                <div>
                                        <span className="hardSoft me-2">
                                            Soft Savings
                                        </span>
                                    <span className="SoftValue">
                                          ${numberWithCommas(iteam.sumSoftSavingMetrics)}
                                      </span>
                                </div>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    );
}

export default AccountList;
