import "./Accounts.css";
import InputCus from "../InputCus/Text/InputCus";
import {useEffect, useState} from "react";
import Select from "../InputCus/Select/Select";
import {CompanyList, DeleteCompany, GetCompanySearch} from "../../Api/Services";
import imgDelete from "../../assets/Icon/Delete, Disabled.png"
import imgDoc from "../../assets/Icon/Document, Content.png"
import {confirmAlert} from 'react-confirm-alert';
import imgPlus from "../../assets/Icon/pluse.png"
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

function Accounts() {
    const [Search, setSearch] = useState({})
    const [Companies, setCompanies] = useState([])
    const [DrapeDownCompanies, setDrapeDownCompanies] = useState([])
    const [FormIdentity, setFormIdentity] = useState();
    const [isLoading, setLoading] = useState(true);

    const navigate = useNavigate();

    const start = async () => {
        try {
            setFormIdentity(JSON.parse(localStorage.getItem('Identity')));
            const GetCompanySearchData = await GetCompanySearch(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetCompanySearchData.status && GetCompanySearchData.status === 200) {
                setCompanies(GetCompanySearchData.data.data.dataList);
                const CompanyListData = await CompanyList({})
                if (CompanyListData.status && CompanyListData.status === 200) {
                    let a = [];
                    CompanyListData.data.data.forEach((e) => {
                        a.push({value: e.id, title: e.description})
                    })
                    setDrapeDownCompanies(a)
                }
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    const Clear = async () => {
        setSearch({})
        setLoading(true)
        try {
            const GetCompanySearchData = await GetCompanySearch(
                {
                    gridParameters: {
                        pageNumber: 1,
                        pageSize: 1000
                    }
                }
            )
            if (GetCompanySearchData.status && GetCompanySearchData.status === 200) {
                setCompanies(GetCompanySearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const Searched = async () => {
        try {
            setLoading(true)
            let searching = {...Search}
            let Paging = {
                gridParameters: {
                    pageNumber: 1,
                    pageSize: 1000
                }
            }
            const GetCompanySearchData = await GetCompanySearch({...searching, ...Paging})
            if (GetCompanySearchData.status && GetCompanySearchData.status === 200) {
                setCompanies(GetCompanySearchData.data.data.dataList);
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    const confirmDelete = (e) => {
        confirmAlert({
            title: 'Delete Account',
            message: 'Are You Sure To Do Remove Account ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteItem(e)
                },
                {
                    label: 'No',
                }
            ]
        });
    };
    const DeleteItem = async (e) => {

        try {
            setLoading(true)
            const DeleteCompanyData = await DeleteCompany(e)
            if (DeleteCompanyData.status && DeleteCompanyData.status === 200) {
                toast.success('Your information has been delete!');
                await start();
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }

    }
    const Add = () => {
        navigate("/Panel/Accounts/Add");
    }
    const Edite = (id) => {
        navigate("/Panel/Accounts/Edite/" + id);
    }

    const handleChangeSearch = (e) => {
        const newSearch = {...Search};
        newSearch[e.target.id] = e.target.value;
        setSearch(newSearch);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-start align-items-center flex-column element p-5">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <span className="AccountsTitle">Account</span>
                        {FormIdentity && FormIdentity.Account.Create && <img onClick={() => {
                            Add()
                        }} src={imgPlus} alt=""/>}
                    </div>
                    <div className="d-flex justify-content-start align-items-center flex-wrap">
                        <Select
                            type="text"
                            name="companyId"
                            id="companyId"
                            text="Account"
                            value={Search.companyId || ""}
                            onChange={handleChangeSearch}
                            option={DrapeDownCompanies}
                        />
                        <InputCus
                            type="text"
                            name="contactPerson"
                            id="contactPerson"
                            text="Contact Person"
                            value={Search.contactPerson || ""}
                            onChange={handleChangeSearch}
                        />
                        <InputCus
                            type="text"
                            name="Email"
                            id="Email"
                            text="Email"
                            value={Search.Email || ""}
                            onChange={handleChangeSearch}

                        />
                        <InputCus
                            type="text"
                            name="Phone"
                            id="Phone"
                            text="Phone"
                            value={Search.Phone || ""}
                            onChange={handleChangeSearch}

                        />
                    </div>
                    <div className="d-flex justify-content-start align-items-center w-100">
                        <button onClick={() => {
                            Searched()
                        }} className="m-3 btnSearch">Search
                        </button>
                        <button onClick={() => {
                            Clear()
                        }} className="btnCancel">Clear
                        </button>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 p-2 tableC">
                        <table className="table">
                            <thead>
                            <tr className="trTable">
                                <th>Logo</th>
                                <th>Account</th>
                                <th>Contact Person</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {Companies.map((item, key) =>
                                <tr key={key}>
                                    <td>
                                        <img alt="" width={30} height={30}
                                             src={`data:image/jpeg;base64,${item.logo}`}
                                        />
                                    </td>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                        {item.contactPerson}
                                    </td>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        {item.phone}
                                    </td>

                                    <td className="td-actions">
                                        {FormIdentity && FormIdentity.Account.Delete &&
                                            <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                  title="Remove Accounts ...">
                                            <img className="me-2" src={imgDelete} alt="Remove Accounts ..."
                                                 onClick={() => {
                                                     confirmDelete(item.id)
                                                 }}/>
                                            </span>}
                                        {FormIdentity && FormIdentity.Account.Update &&
                                            <span className="d-inline-block" tabIndex="0" data-bs-toggle="tooltip"
                                                  title="Edite Accounts ...">
                                            <img src={imgDoc} alt="Edite Accounts ..." onClick={() => {
                                                Edite(item.id)
                                            }}/>
                                             </span>}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accounts;
