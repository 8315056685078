import './DetCompany.css'
import React, {useEffect, useState} from "react";
import gicon from "../../../../assets/Icon/Statistics, Analytics,Arrow, Up, Circle,.png"
import {DashboardGetAccountMonetary, GetHardSoftSavingAccount} from "../../../../Api/Services";
import {numberWithCommas} from "../../../../Common/Tools";

function DetCompany(props) {
    const [projectsMonetary, setprojectsMonetary] = useState();
    const [Soft, setSoft] = useState();
    const [isLoading, setLoading] = useState(true);


    const start = async () => {
        try {
            const DashboardGetAccountMonetaryData = await DashboardGetAccountMonetary(props.companyId)
            if (DashboardGetAccountMonetaryData.status && DashboardGetAccountMonetaryData.status === 200) {
                setprojectsMonetary(DashboardGetAccountMonetaryData.data.data.sumAccountMetricsValue)
                const GetHardSoftSavingAccountData = await GetHardSoftSavingAccount(props.companyId)
                if (GetHardSoftSavingAccountData.status && GetHardSoftSavingAccountData.status === 200) {
                    setSoft(GetHardSoftSavingAccountData.data.data[0]);
                }
            }

        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            start();
        }, []
    )

    return (

        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="d-flex justify-content-start align-items-center w-100">
                    <div className="d-flex flex-column GvexMonetaryRadi p-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className='GvexMonetaryRadi-icon d-flex justify-content-center align-items-center'>
                                <img src={props.CompanyLogo ? `data:image/jpeg;base64,${props.CompanyLogo}` : gicon}/>
                            </div>
                            <div className='d-flex justify-content-center align-items-end flex-column'>
                                <span
                                    className="name">{props.CompanyName ? props.CompanyName : "Account MONETARY"}</span>
                                <span
                                    className="value">{projectsMonetary ? "$" + numberWithCommas(projectsMonetary) : "projectsMonetary"}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <div className='d-flex justify-content-center align-items-center'>
                                <span className="nameProjects me-2">Hard Savings</span>
                                <span
                                    className="valueProjects">{Soft ? "$" + numberWithCommas(Soft.sumHardSavingMetrics) : "projectsMonetary"}</span>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <span className="nameProjects me-2">Soft Savings</span>
                                <span
                                    className="valueProjectsSuss">{Soft ? "$" + numberWithCommas(Soft.sumSoftSavingMetrics) : "projectsMonetary"}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetCompany;
