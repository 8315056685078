import "./ProjectsAE.css";
import InputCus from "../InputCus/Text/InputCus";
import {useEffect, useState} from "react";
import {
    AddProjects,
    CompanyList,
    GetProjectSearch,
    GetUserUsername, UpdateProjects,
} from "../../Api/Services";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import AdaptorDTO from "../../Common/AdaptorDTO";
import bg from "../../assets/Image/image 13.png";
import face from "../../assets/Image/face.jpg";
import Select from "../InputCus/Select/Select";

const ModelProject = {
    "projectName": "",
    "startDate": "",
    "endDate": "",
    "projectLead": "",
    "projectSponsor": "",
    "reactionInitialValue": 0,
    "reactionCurrentValue": 0,
    "reactionDescription": "",
    "trainingInitialValue": 0,
    "trainingCurrentValue": 0,
    "trainingDescription": "",
    "applicationInitialValue": 0,
    "applicationCurrentValue": 0,
    "applicationDescription": "",
    "companyId": 0,
}

function ProjectsAE() {
    const [isLoading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [form, setForm] = useState({})
    const [User, setUser] = useState()


    const navigate = useNavigate();

    let {id} = useParams();
    const start = async () => {
        try {
            const CompanyListData = await CompanyList({})
            if (CompanyListData.status && CompanyListData.status === 200) {
                let b = [];
                CompanyListData.data.data.forEach((e) => {
                    b.push({value: e.id, title: e.description})
                })
                setCompanies(b)
                const GetUserUsernameData = await GetUserUsername(localStorage.getItem("Username"))
                if (GetUserUsernameData.status && GetUserUsernameData.status === 200) {
                    setUser(GetUserUsernameData.data.data[0]);
                }
                if (id) {
                    const GetProjectSearchData = await GetProjectSearch({id: id})
                    if (GetProjectSearchData.status && GetProjectSearchData.status === 200) {
                        GetProjectSearchData.data.data.dataList[0].startDate=GetProjectSearchData.data.data.dataList[0].startDate.slice(0,10)
                        GetProjectSearchData.data.data.dataList[0].endDate=GetProjectSearchData.data.data.dataList[0].endDate.slice(0,10)
                        setForm(AdaptorDTO({...ModelProject}, GetProjectSearchData.data.data.dataList[0]));

                    }
                }
            }
        } catch (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
            start();
        }, []
    )

    const AddUpdate = async () => {
        let validation = true
        if (form.projectName === "") {
            toast.error("Fill in the projectName !");
            validation = false;
        }
        if (form.startDate === "") {
            toast.error("Fill in the startDate !");
            validation = false;
        }
        if (form.companyId === 0) {
            toast.error("Fill in the companyId !");
            validation = false;
        }
        if (form.endDate === "") {
            toast.error("Fill in the endDate !");
            validation = false;
        }
        if (form.projectLead === "") {
            toast.error("Fill in the projectLead !");
            validation = false;
        }
        if (form.projectSponsor === "") {
            toast.error("Fill in the projectSponsor !");
            validation = false;
        }
        let startDate = form.startDate.split("-");
        let endDate = form.endDate.split("-");
        if (startDate[0] >= endDate[0]) {
            if (startDate[0] === endDate[0]) {
                if (startDate[2] >= endDate[2]) {
                    if (startDate[2] === endDate[2]) {
                        if (startDate[1] >= endDate[1]) {
                            toast.error("Error on Start Date Or End Date  !");
                            validation = false;
                        }
                    } else {
                        toast.error("Error on Start Date Or End Date  !");
                        validation = false;
                    }
                }
            } else {
                toast.error("Error on Start Date Or End Date  !");
                validation = false;
            }
        }

        if (validation) {
            setLoading(true)
            try {
                if (id) {
                    const UpdateProjectsData = await UpdateProjects(form, id)
                    if (UpdateProjectsData.status && UpdateProjectsData.status === 200) {
                        toast.success('Your information has been update!');
                        navigate("/Panel/Projects");
                    }
                } else {
                    const AddProjectsData = await AddProjects(form)
                    if (AddProjectsData.status && AddProjectsData.status === 200) {
                        toast.success('Your information has been add!');
                        navigate("/Panel/Projects");
                    }
                }
            } catch (error) {
                console.error("Error during login:", error);
            } finally {
                setLoading(false);

            }
        }
    }

    function handleChangeSearch(e) {
        const newForm = {...form};
        newForm[e.target.id] = e.target.value;
        setForm(newForm);
    }

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading"}>
                <div className="w-100 d-flex justify-content-between align-items-start p-5">
                    <div className="d-flex justify-content-start align-items-center flex-column element me-5 w-75">
                        <div className="d-flex justify-content-start align-items-center flex-wrap p-5 ">
                            <Select
                                type="Account"
                                name="companyId"
                                id="companyId"
                                text="name"
                                value={form.companyId || ""}
                                onChange={handleChangeSearch}
                                option={companies}
                            />
                            <InputCus
                                type="text"
                                name="projectName"
                                id="projectName"
                                text="Project"
                                value={form.projectName || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="date"
                                name="startDate"
                                id="startDate"
                                text="Start Date"
                                value={form.startDate || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="date"
                                name="endDate"
                                id="endDate"
                                text="End Date"
                                value={form.endDate || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="projectLead"
                                id="projectLead"
                                text="Project Lead"
                                value={form.projectLead || ""}
                                onChange={handleChangeSearch}
                            />
                            <InputCus
                                type="text"
                                name="projectSponsor"
                                id="projectSponsor"
                                text="Project Sponsor"
                                value={form.projectSponsor || ""}
                                onChange={handleChangeSearch}
                            />
                        </div>
                        <div className="d-flex justify-content-start align-items-center w-100 ps-5 pb-5">
                            <button onClick={() => {
                                AddUpdate()
                            }} className="butAUAccount">{id ? "Update Company" : "Add Company"} </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center element flex-column w-25">
                        <div className="d-flex justify-content-center align-items-center mt-4 ">
                            <img className="w-75"
                                 src={User && User.companyCoverPic ? `data:image/jpeg;base64,${User.companyCoverPic}` : {bg}}
                                 alt=""/>
                            <img className="face"
                                 src={User && User.userProfilePic ? `data:image/jpeg;base64,${User.userProfilePic}` : face}
                                 alt=""/>
                        </div>
                        <span className="m-2">{User && User.firstName + " " + User && User.lastName}</span>
                        <span className="m-2">{User && User.email}</span>
                        <span className="m-2 mb-4">{User && User.jobTitleName}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectsAE;
