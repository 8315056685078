import React, { useEffect, useState } from "react";
import { DashboardGetGvexMonthlyGainedValue } from "../../../../Api/Services";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


function BarSuperAdmin({ ChartColor }) {

    const [isLoading, setLoading] = useState(true);
    const [Data, setData] = useState([])
    const [Name, setName] = useState([])

    const start = async () => {
        try {

            const DashboardGetGvexMonthlyGainedValueData = await DashboardGetGvexMonthlyGainedValue()
            if (DashboardGetGvexMonthlyGainedValueData.status && DashboardGetGvexMonthlyGainedValueData.status === 200) {
                let MonthlyGained = DashboardGetGvexMonthlyGainedValueData.data.data;
                // let label = [["Date"]];

                let listMet = [];
                MonthlyGained.forEach(e => {
                    if (!listMet.includes(e.accountName)) {
                        listMet.push(e.accountName);
                        // label[0].push(e.accountName);
                    }
                });
                setName(listMet)
                console.log(listMet);
                let listDate = [];
                MonthlyGained.forEach(e => {
                    if (!listDate.includes(e.dataYear + "/" + e.dataMonth)) {
                        listDate.push(e.dataYear + "/" + e.dataMonth);
                    }
                });

                let data = [];
                listDate.forEach(itemDate => {
                    let ee = { Date: itemDate }
                    listMet.forEach(itemNname => {
                        let t = MonthlyGained.find(e => e.dataYear + "/" + e.dataMonth === itemDate && e.accountName === itemNname)
                        ee[itemNname] = t === undefined ? 0 : t.sumSavingValue
                    })
                    data.push(ee)
                });

                console.log(data)

                data.splice(0, data.length - 5)
                console.log(data)

                setData(data)
            }

        } catch
        (error) {
            console.error("Error during login:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        start();
    }, []
    )

    return (
        <div>
            {isLoading &&
                <div className="d-flex justify-content-center align-items-center">
                    <div className="lds-facebook">
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            }
            <div className={isLoading ? "startLoading" : "stateLoading boxShadow"}>
                <div className="d-flex justify-content-center align-items-center flex-column w-100 element">
                    <div className="d-flex justify-content-center align-items-center w-100 m-5">
                        <span className="titleChart">Monthly Earned Value</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 ">

                        <BarChart
                            width={1000}
                            // width={window.innerWidth-300}
                            height={300}
                            data={Data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="0" stroke="#E5E5EF" vertical={false} />
                            <XAxis dataKey="Date" fontSize={"10px"} />
                            <YAxis fontSize={"11px"} />
                            <Tooltip />
                            <Legend />
                            {Name.map((item, key) =>
                                <Bar dataKey={item} fill={ChartColor[key]} radius={"10"} strokeWidth="3" />
                            )}
                            {/* <Bar dataKey="pv" fill="#8884d8" />
                            <Bar dataKey="uv" fill="#82ca9d" /> */}
                        </BarChart>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default BarSuperAdmin;
